export type ContributorProps = {
  full_name: string;
  last_name: string;
  ORCID: string | null;
  google_scholar: string | null;
  'bbp-member': boolean;
};

export const CONTRIBUTORS_LIST: ContributorProps[] = [
  {
    full_name: 'Romy Aardse',
    last_name: 'Aardse',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'M. Abdelaziz',
    last_name: 'Abdelaziz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marwan Abdellah',
    last_name: 'Abdellah',
    ORCID: '0000-0003-0751-2300',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Shabbir S. Abdul',
    last_name: 'Abdul',
    ORCID: null,
    google_scholar: 'JoU7uogAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Adnan Abid',
    last_name: 'Abid',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stanislaw R. Adaszewski',
    last_name: 'Adaszewski',
    ORCID: null,
    google_scholar: 'hPrH4roAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ad Aertsen',
    last_name: 'Aertsen',
    ORCID: null,
    google_scholar: 'gLEsMrYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jens Agerberg',
    last_name: 'Agerberg',
    ORCID: '0000-0002-1513-5069',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Carlos Aguado Sanchez',
    last_name: 'Aguado Sanchez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Paulo Aguiar',
    last_name: 'Aguiar',
    ORCID: '0000-0003-4164-5713',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marco Agus',
    last_name: 'Agus',
    ORCID: '0000-0003-2752-3525',
    google_scholar: 'jNN3FacAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'A. Ailamaki',
    last_name: 'Ailamaki',
    ORCID: null,
    google_scholar: 'YdrkeNwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'James B. Aimone',
    last_name: 'Aimone',
    ORCID: null,
    google_scholar: 'fIhoSlAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ugo Albanese',
    last_name: 'Albanese',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sarael Alcauter',
    last_name: 'Alcauter',
    ORCID: '0000-0001-8182-6370',
    google_scholar: 'fAzVWFQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yasser Aleman-Gomez',
    last_name: 'Aleman-Gomez',
    ORCID: null,
    google_scholar: 'ij8pBrQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jérémy Alexandre',
    last_name: 'Alexandre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Igor Allaman',
    last_name: 'Allaman',
    ORCID: '0000-0002-7525-0536',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Lara Almeida',
    last_name: 'Almeida',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Lidia Alonso-Nanclares',
    last_name: 'Alonso-Nanclares',
    ORCID: '0000-0003-2649-7097',
    google_scholar: 'OCwOAuYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'A. Ambrosano',
    last_name: 'Ambrosano',
    ORCID: null,
    google_scholar: 'zQ4r7e4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Oren Amsalem',
    last_name: 'Amsalem',
    ORCID: '0000-0002-8070-0378',
    google_scholar: '6uSmt8YAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Katrin Amunts',
    last_name: 'Amunts',
    ORCID: null,
    google_scholar: 'cZ5iKNMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sophia Ananiadou',
    last_name: 'Ananiadou',
    ORCID: '0000-0002-4097-9191',
    google_scholar: 'quhi-K0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Costas A. Anastassiou',
    last_name: 'Anastassiou',
    ORCID: '0000-0002-6793-0611',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stewart A. Anderson',
    last_name: 'Anderson',
    ORCID: null,
    google_scholar: 'fjkztRQAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Ole A. Andreassen',
    last_name: 'Andreassen',
    ORCID: null,
    google_scholar: 'dilW0WsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andreas Andreou',
    last_name: 'Andreou',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Dora Angelaki',
    last_name: 'Angelaki',
    ORCID: null,
    google_scholar: 'u5KcrAwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Emmanouil Angelidis',
    last_name: 'Angelidis',
    ORCID: null,
    google_scholar: 'citb8g0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Nicolas Antille',
    last_name: 'Antille',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Alejandro Antón-Fernández',
    last_name: 'Antón-Fernández',
    ORCID: null,
    google_scholar: 'A4AajP4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stefano Maximiliano Antonel',
    last_name: 'Antonel',
    ORCID: '0009-0008-1042-2086',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Alberto Antonietti',
    last_name: 'Antonietti',
    ORCID: null,
    google_scholar: 'sNTLLMkAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'H. Anwar',
    last_name: 'Anwar',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shailesh Appukuttan',
    last_name: 'Appukuttan',
    ORCID: '0000-0002-0148-8023',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Cecilia N. Arighi',
    last_name: 'Arighi',
    ORCID: '0000-0002-0803-4817',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Anton Arkhipov',
    last_name: 'Arkhipov',
    ORCID: '0000-0003-1106-8310',
    google_scholar: 'apMaQzQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexis Arnaudon',
    last_name: 'Arnaudon',
    ORCID: null,
    google_scholar: 'A8HW0pAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jonathan Arreguit',
    last_name: 'Arreguit',
    ORCID: null,
    google_scholar: '4TqgWQYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Selim Arsever',
    last_name: 'Arsever',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Kalaimakan Arulkandarajah',
    last_name: 'Arulkandarajah',
    ORCID: null,
    google_scholar: 'HonR-X8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Senthil Arumugam',
    last_name: 'Arumugam',
    ORCID: '0000-0001-6733-4679',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Giorgio A. Ascoli',
    last_name: 'Ascoli',
    ORCID: null,
    google_scholar: 'Qq5H3-QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Guy Atenekeng',
    last_name: 'Atenekeng',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Omar Awile',
    last_name: 'Awile',
    ORCID: null,
    google_scholar: 'pjb23CUAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Johannes C Baayen',
    last_name: 'Baayen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Norbert Babai',
    last_name: 'Babai',
    ORCID: null,
    google_scholar: 'Ge_rIXcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rembrandt Bakker',
    last_name: 'Bakker',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yalbi I. Balderas-Martínez',
    last_name: 'Balderas-Martínez',
    ORCID: null,
    google_scholar: '2hvieWAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yoav Banitt',
    last_name: 'Banitt',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mauricio Barahona',
    last_name: 'Barahona',
    ORCID: null,
    google_scholar: 'weulBoAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Omri Barak',
    last_name: 'Barak',
    ORCID: null,
    google_scholar: '6BrZ2isAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Cori Bargmann',
    last_name: 'Bargmann',
    ORCID: null,
    google_scholar: 'Wd7XWVYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bernabe Linares-Barranco',
    last_name: 'Barranco',
    ORCID: null,
    google_scholar: 'yjeEP_EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'German Barrionuevo',
    last_name: 'Barrionuevo',
    ORCID: null,
    google_scholar: '_qGOTkwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Fernando A. Barrios',
    last_name: 'Barrios',
    ORCID: null,
    google_scholar: 'iOiINvQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Natali Barros Zulaica',
    last_name: 'Barros Zulaica',
    ORCID: '0000-0002-1358-2236',
    google_scholar: 'gBLZCcoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Chiara Bartolozzi',
    last_name: 'Bartolozzi',
    ORCID: null,
    google_scholar: 'l7afPpIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Julian Bartram',
    last_name: 'Bartram',
    ORCID: null,
    google_scholar: 'WVCGA_cAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Erhan Bas',
    last_name: 'Bas',
    ORCID: null,
    google_scholar: 'HmnRFhgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Patricia Bassereau',
    last_name: 'Bassereau',
    ORCID: null,
    google_scholar: 'Zxqe6o4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Riza Batista-Navarro',
    last_name: 'Batista-Navarro',
    ORCID: '0000-0001-6693-7531',
    google_scholar: 'fRBJmp9gk_cC',
    'bbp-member': false,
  },
  {
    full_name: 'Stéphani Battini',
    last_name: 'Battini',
    ORCID: null,
    google_scholar: 'UKfnGmIAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Dieter A. Baumgartner',
    last_name: 'Baumgartner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jonathan R. Beaumont',
    last_name: 'Beaumont',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'A. Beche',
    last_name: 'Beche',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Ulrike Becken',
    last_name: 'Becken',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'James A. Bednar',
    last_name: 'Bednar',
    ORCID: null,
    google_scholar: 'h0e2kMQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'C. Bekas',
    last_name: 'Bekas',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Davide Bella',
    last_name: 'Bella',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Pierre Bellec',
    last_name: 'Bellec',
    ORCID: '0000-0002-9111-0699',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Paloma Gonzalez-Bellido',
    last_name: 'Bellido',
    ORCID: null,
    google_scholar: 'OpaFwzoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'R. Bellofatto',
    last_name: 'Bellofatto',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ruth Benavides-Piccione',
    last_name: 'Benavides-Piccione',
    ORCID: null,
    google_scholar: 'u7gxzWEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Adrien Berchet',
    last_name: 'Berchet',
    ORCID: null,
    google_scholar: 'KPR3ks8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jim Berg',
    last_name: 'Berg',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Thomas K. Berger',
    last_name: 'Berger',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Samuel F. Berkovic',
    last_name: 'Berkovic',
    ORCID: null,
    google_scholar: 'kq6STsoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'J. Bernard',
    last_name: 'Bernard',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Yann Bernardinelli',
    last_name: 'Bernardinelli',
    ORCID: null,
    google_scholar: 'xH4XnWUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Simon Berneche',
    last_name: 'Berneche',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nathan R. Bernier',
    last_name: 'Bernier',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marine Bertschy',
    last_name: 'Bertschy',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Harish Bhaskaran',
    last_name: 'Bhaskaran',
    ORCID: null,
    google_scholar: 'jwZZEfMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'J. Biddiscombe',
    last_name: 'Biddiscombe',
    ORCID: null,
    google_scholar: 'wgQF8g8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Concha Bielza',
    last_name: 'Bielza',
    ORCID: '0000-0001-7109-2668',
    google_scholar: 'zwSj1n8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ahmet Bilgili',
    last_name: 'Bilgili',
    ORCID: null,
    google_scholar: 'PQNe3w0AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Yazan N. Billeh',
    last_name: 'Billeh',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Francesca Binda',
    last_name: 'Binda',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Justas Birgiolas',
    last_name: 'Birgiolas',
    ORCID: null,
    google_scholar: 'y9mJbiYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Laurynas Biveinis',
    last_name: 'Biveinis',
    ORCID: null,
    google_scholar: 'A2wnCEQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jan G. Bjaalie',
    last_name: 'Bjaalie',
    ORCID: null,
    google_scholar: 'TkaQ2w8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kim T Blackwell',
    last_name: 'Blackwell',
    ORCID: null,
    google_scholar: 'QsYQTBIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jorge Blanco Alonso',
    last_name: 'Blanco Alonso',
    ORCID: '0009-0000-4872-3855',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Camilla H. Blixhavn',
    last_name: 'Blixhavn',
    ORCID: null,
    google_scholar: 'cLgpM-oAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jocelyne Bloch',
    last_name: 'Bloch',
    ORCID: '0000-0002-6405-1590',
    google_scholar: 'EAGtm20AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Inga Blundell',
    last_name: 'Blundell',
    ORCID: null,
    google_scholar: 'yTJqVb4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniele Boccalini',
    last_name: 'Boccalini',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Elvis Boci',
    last_name: 'Boci',
    ORCID: '0009-0002-5871-4164',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Daniya Boges',
    last_name: 'Boges',
    ORCID: '0009-0008-2629-7451',
    google_scholar: '07_ys3IAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'B. Bohara',
    last_name: 'Bohara',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Luca Leonardo Bologna',
    last_name: 'Bologna',
    ORCID: '0000-0002-7280-9285',
    google_scholar: 'JbA9MtoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sebastian Bonhoeffer',
    last_name: 'Bonhoeffer',
    ORCID: '0000-0001-8052-3925',
    google_scholar: 'L_JGehAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kristofer E. Bouchard',
    last_name: 'Bouchard',
    ORCID: null,
    google_scholar: 'mDvGOLkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Graeme M. Bragg',
    last_name: 'Bragg',
    ORCID: null,
    google_scholar: '858QgrkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Johanni Brea',
    last_name: 'Brea',
    ORCID: null,
    google_scholar: 'nZ0m0xUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Romain Brette',
    last_name: 'Brette',
    ORCID: '0000-0003-0110-1623',
    google_scholar: 'lEHiPU4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alessandro Bria',
    last_name: 'Bria',
    ORCID: null,
    google_scholar: 'zgA4VsQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marc Briquet',
    last_name: 'Briquet',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andrew D. Brown',
    last_name: 'Brown',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yury Brukau',
    last_name: 'Brukau',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Alexander Bryson',
    last_name: 'Bryson',
    ORCID: '0000-0002-0033-8197',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alessio Paolo Buccino',
    last_name: 'Buccino',
    ORCID: null,
    google_scholar: 'UmLW1NAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Valérie Buchillier',
    last_name: 'Buchillier',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Anatoly Buchin',
    last_name: 'Buchin',
    ORCID: null,
    google_scholar: 'w8hTOSsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Julian Budd',
    last_name: 'Budd',
    ORCID: '0000-0001-8614-9989',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Nenad Buncic',
    last_name: 'Buncic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Andreas Burkhalter',
    last_name: 'Burkhalter',
    ORCID: null,
    google_scholar: 'gNQwGFgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yves Burnod',
    last_name: 'Burnod',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Randal Burns',
    last_name: 'Burns',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Olivier Burri',
    last_name: 'Burri',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'György Buzsáki',
    last_name: 'Buzsáki',
    ORCID: null,
    google_scholar: 'DVl9E8YAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Weidong Cai',
    last_name: 'Cai',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Matteo Caleo',
    last_name: 'Caleo',
    ORCID: null,
    google_scholar: 'jLCgWXIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Corrado Calì',
    last_name: 'Calì',
    ORCID: '0000-0003-4856-0835',
    google_scholar: 'PRkBpAwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David Campos',
    last_name: 'Campos',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andrea Camposeo',
    last_name: 'Camposeo',
    ORCID: null,
    google_scholar: 'a8w-Ff4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'C. Cannia',
    last_name: 'Cannia',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christopher J. Cannistraci',
    last_name: 'Cannistraci',
    ORCID: null,
    google_scholar: 'Npy-GCQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Robert Cannon',
    last_name: 'Cannon',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Matteo Cantarelli',
    last_name: 'Cantarelli',
    ORCID: null,
    google_scholar: 'HqmRfWkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Nicola Cantarutti',
    last_name: 'Cantarutti',
    ORCID: '0000-0002-0441-7159',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Elisa Capuana',
    last_name: 'Capuana',
    ORCID: null,
    google_scholar: '8BsFsXUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tristan Carel',
    last_name: 'Carel',
    ORCID: '0000-0003-0941-9986',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Alan Carleton',
    last_name: 'Carleton',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nicholas T. Carnevale',
    last_name: 'Carnevale',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Francesco Casalegno',
    last_name: 'Casalegno',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Antonino Cassara',
    last_name: 'Cassara',
    ORCID: null,
    google_scholar: 'dmraubIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Giacomo Castiglioni',
    last_name: 'Castiglioni',
    ORCID: null,
    google_scholar: 'qAqsm6EAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Alessandro Cattabiani',
    last_name: 'Cattabiani',
    ORCID: null,
    google_scholar: 'qwMY7tAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Bruno Cauli',
    last_name: 'Cauli',
    ORCID: '0000-0003-1471-4621',
    google_scholar: 'vMb5S-UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'N. Cauli',
    last_name: 'Cauli',
    ORCID: null,
    google_scholar: 'YgbNdbUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'N. Alex Cayco-Gajic',
    last_name: 'Cayco-Gajic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Barbara Chabriw',
    last_name: 'Chabriw',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Wojciech Chachólski',
    last_name: 'Chachólski',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Athanassia Chalimourda',
    last_name: 'Chalimourda',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Frances Chance',
    last_name: 'Chance',
    ORCID: null,
    google_scholar: 'DluuU7UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Florian Chapotot',
    last_name: 'Chapotot',
    ORCID: null,
    google_scholar: 'nTu0AQgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jean-Cédric Chappelier',
    last_name: 'Chappelier',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Chartrand',
    last_name: 'Chartrand',
    ORCID: null,
    google_scholar: 'INIFj2kAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andrew Chatr-Aryamontri',
    last_name: 'Chatr-Aryamontri',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jean‐Yves Chatton',
    last_name: 'Chatton',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Hanbo Chen',
    last_name: 'Chen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Weiliang Chen',
    last_name: 'Chen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Li Cheng',
    last_name: 'Cheng',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Grigori Chevtchenko',
    last_name: 'Chevtchenko',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Ann-Shyn Chiang',
    last_name: 'Chiang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Lucy Chilton',
    last_name: 'Chilton',
    ORCID: null,
    google_scholar: 'sGj-oQkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Giuseppe Chindemi',
    last_name: 'Chindemi',
    ORCID: '0000-0001-6872-2366',
    google_scholar: '2Bg_RB0AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Hui-Jou Chou',
    last_name: 'Chou',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Dennis V Christensen',
    last_name: 'Christensen',
    ORCID: null,
    google_scholar: '4Pl5CDAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Martin Ejsing Christensen',
    last_name: 'Christensen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Miyoung Chun',
    last_name: 'Chun',
    ORCID: null,
    google_scholar: 'BO_fMrEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'George Church',
    last_name: 'Church',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Laura Cif',
    last_name: 'Cif',
    ORCID: null,
    google_scholar: 'LJSVS6gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Oscar Cipolato',
    last_name: 'Cipolato',
    ORCID: null,
    google_scholar: 'GrVRdrcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Francisco Clascá',
    last_name: 'Clascá',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Thomas A Cleland',
    last_name: 'Cleland',
    ORCID: null,
    google_scholar: 'oNAtoyEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Estelle E. Clerc',
    last_name: 'Clerc',
    ORCID: '0009-0003-6046-2856',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Hollis Cline',
    last_name: 'Cline',
    ORCID: null,
    google_scholar: 'Prfdr10AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas G. Close',
    last_name: 'Close',
    ORCID: null,
    google_scholar: 'fBka2hcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Coca',
    last_name: 'Coca',
    ORCID: null,
    google_scholar: 'l5c4xxgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jay S. Coggan',
    last_name: 'Coggan',
    ORCID: '0000-0002-2270-1989',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Julien Cohen-Adad',
    last_name: 'Cohen-Adad',
    ORCID: null,
    google_scholar: '6cAZ028AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Cristina Colangelo',
    last_name: 'Colangelo',
    ORCID: null,
    google_scholar: 'lHdggD8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Todd Coleman',
    last_name: 'Coleman',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Núria Tort-Colet',
    last_name: 'Colet',
    ORCID: '0000-0002-9708-9755',
    google_scholar: 'bmZjZY4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eloise Colnot',
    last_name: 'Colnot',
    ORCID: null,
    google_scholar: 'a2VxuHMAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Gloria Colombo',
    last_name: 'Colombo',
    ORCID: null,
    google_scholar: 'IAOSVeoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eduardo Conde-Sousa',
    last_name: 'Conde-Sousa',
    ORCID: null,
    google_scholar: 'xi0xi2AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David O’Connor',
    last_name: 'Connor',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Emilia Conti',
    last_name: 'Conti',
    ORCID: null,
    google_scholar: 'ckgjk1UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Valerio Conti',
    last_name: 'Conti',
    ORCID: '0000-0002-8326-6378',
    google_scholar: '7cRwCIwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gabriela Contreras',
    last_name: 'Contreras',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Laurel Cooper',
    last_name: 'Cooper',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nicolas Cornu',
    last_name: 'Cornu',
    ORCID: null,
    google_scholar: '935nYOgAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Irene Costantini',
    last_name: 'Costantini',
    ORCID: null,
    google_scholar: 'fq-UlpQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Benoit Coste',
    last_name: 'Coste',
    ORCID: null,
    google_scholar: 'w6W9oe0AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Sil Coulter',
    last_name: 'Coulter',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jean-Denis Courcol',
    last_name: 'Courcol',
    ORCID: '0000-0002-9351-1461',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Gregoire Courtine',
    last_name: 'Courtine',
    ORCID: null,
    google_scholar: 'Jvd6Y1UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel N. Cox',
    last_name: 'Cox',
    ORCID: null,
    google_scholar: 'o162W3gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'R. Cameron Craddock',
    last_name: 'Craddock',
    ORCID: '0000-0002-4950-1303',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Francesco Cremonesi',
    last_name: 'Cremonesi',
    ORCID: '0000-0003-1027-485X',
    google_scholar: 'dHm1KE1zGioC',
    'bbp-member': false,
  },
  {
    full_name: 'Joel Criado',
    last_name: 'Criado',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sharon M Crook',
    last_name: 'Crook',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Meritxell Bach Cuadra',
    last_name: 'Cuadra',
    ORCID: null,
    google_scholar: 'UoZ4neoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ryan John A. Cubero',
    last_name: 'Cubero',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ralph Etienne Cummings',
    last_name: 'Cummings',
    ORCID: null,
    google_scholar: 'AA16968AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hermann Cuntz',
    last_name: 'Cuntz',
    ORCID: null,
    google_scholar: '9g7Uj-MAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alessandro Curioni',
    last_name: 'Curioni',
    ORCID: null,
    google_scholar: 'EidvBK8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Justin Curry',
    last_name: 'Curry',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: "Egidio D'Angelo",
    last_name: "D'Angelo",
    ORCID: null,
    google_scholar: 'ZAFulUQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Snigdha Dagar',
    last_name: 'Dagar',
    ORCID: null,
    google_scholar: 'tgLluUkAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'R. Daher',
    last_name: 'Daher',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Hong-Jie Dai',
    last_name: 'Dai',
    ORCID: null,
    google_scholar: 'qsKMOtwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kael Dai',
    last_name: 'Dai',
    ORCID: '0000-0002-2147-5895',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Tanguy Damart',
    last_name: 'Damart',
    ORCID: null,
    google_scholar: 'UAiver8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Chinh Dang',
    last_name: 'Dang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Daniels',
    last_name: 'Daniels',
    ORCID: '0000-0002-0456-1214',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Simon M. Danner',
    last_name: 'Danner',
    ORCID: null,
    google_scholar: 'fRSSZRUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Suman Datta',
    last_name: 'Datta',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andrew P. Davison',
    last_name: 'Davison',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Kaushik De',
    last_name: 'De',
    ORCID: null,
    google_scholar: '_LLrUGAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marc de Kamps',
    last_name: 'de Kamps',
    ORCID: '0000-0001-7162-4425',
    google_scholar: 'OS87KEYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christiaan P. J. de Kock',
    last_name: 'de Kock',
    ORCID: '0000-0002-6697-0179',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stephanie de La Rochefoucauld',
    last_name: 'de La Rochefoucauld',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pedro de Miguel Anasagasti',
    last_name: 'de Miguel Anasagasti',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Erik De Schutter',
    last_name: 'De Schutter',
    ORCID: '0000-0001-8618-5138',
    google_scholar: 'eRZk04QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Henry DeAizpurua',
    last_name: 'DeAizpurua',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Javier DeFelipe',
    last_name: 'DeFelipe',
    ORCID: null,
    google_scholar: 'lvjKuYkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yair Deitcher',
    last_name: 'Deitcher',
    ORCID: null,
    google_scholar: 'SWpYm6IAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Baudouin Del Marmol',
    last_name: 'Del Marmol',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Fabien Delalondre',
    last_name: 'Delalondre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Emilie Delattre',
    last_name: 'Delattre',
    ORCID: '0000-0002-5046-1804',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Vincent Delattre',
    last_name: 'Delattre',
    ORCID: null,
    google_scholar: 'B4DiBAYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Delemontex',
    last_name: 'Delemontex',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Berat Denizdurduran',
    last_name: 'Denizdurduran',
    ORCID: null,
    google_scholar: 'BtAhAEEAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Winfried Denk',
    last_name: 'Denk',
    ORCID: null,
    google_scholar: 'pFOcaHAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Denman',
    last_name: 'Denman',
    ORCID: null,
    google_scholar: 'uru5jAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Oliver Denninger',
    last_name: 'Denninger',
    ORCID: null,
    google_scholar: 'qSSPdr8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Pauline Depierre',
    last_name: 'Depierre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sebastien Dery',
    last_name: 'Dery',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stefan Deser',
    last_name: 'Deser',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jordan DeSha',
    last_name: 'DeSha',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alain Destexhe',
    last_name: 'Destexhe',
    ORCID: null,
    google_scholar: 'x-MyT34AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Adrien Devresse',
    last_name: 'Devresse',
    ORCID: '0000-0003-0071-3265',
    google_scholar: 'k5QD3TwAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Antonino Paolo Di Giovanna',
    last_name: 'Di Giovanna',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jafet Villafranca Díaz',
    last_name: 'Díaz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sandra Diaz-Pier',
    last_name: 'Diaz-Pier',
    ORCID: null,
    google_scholar: 'Fnk3RaEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin K. Dichter',
    last_name: 'Dichter',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Hugo Dictus',
    last_name: 'Dictus',
    ORCID: '0000-0003-2980-5737',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Markus Diesmann',
    last_name: 'Diesmann',
    ORCID: '0000-0002-2308-5727',
    google_scholar: 'q4bTHJoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexander Dietz',
    last_name: 'Dietz',
    ORCID: '0000-0002-6400-9958',
    google_scholar: 'IzGmpugAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Rüdiger Dillman',
    last_name: 'Dillman',
    ORCID: null,
    google_scholar: '8q019n8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Regina Dittmann',
    last_name: 'Dittmann',
    ORCID: null,
    google_scholar: 'uzAuOcwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Paweł Dłotko',
    last_name: 'Dłotko',
    ORCID: null,
    google_scholar: 'znDLoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alastair F. Donaldson',
    last_name: 'Donaldson',
    ORCID: null,
    google_scholar: 'ZIUuqNwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Elisa Donati',
    last_name: 'Donati',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Doron',
    last_name: 'Doron',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stijn Van Dorp',
    last_name: 'Dorp',
    ORCID: null,
    google_scholar: '40hhHA8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Matthijs C. Dorst',
    last_name: 'Dorst',
    ORCID: null,
    google_scholar: 'mAfPOB4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jonathan Downar',
    last_name: 'Downar',
    ORCID: '0000-0002-5140-174X',
    google_scholar: '7nHzvswAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bogdan Draganski',
    last_name: 'Draganski',
    ORCID: '0000-0002-5159-5919',
    google_scholar: 'X9iTRWkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jakub Dranczewski',
    last_name: 'Dranczewski',
    ORCID: null,
    google_scholar: 'FGCxu_cAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Shaul Druckmann',
    last_name: 'Druckmann',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Kai Du',
    last_name: 'Du',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Raphael Dumusc',
    last_name: 'Dumusc',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Katharine Dunlop',
    last_name: 'Dunlop',
    ORCID: '0000-0003-4366-7692',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michaël Dupont',
    last_name: 'Dupont',
    ORCID: null,
    google_scholar: 'S18swMcAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Salvador Dura-Bernal',
    last_name: 'Dura-Bernal',
    ORCID: '0000-0002-8561-5324',
    google_scholar: 'TFy7ncUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'James Dynes',
    last_name: 'Dynes',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Matt Earnshaw',
    last_name: 'Earnshaw',
    ORCID: null,
    google_scholar: 'TLeTffcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'András Ecker',
    last_name: 'Ecker',
    ORCID: '0000-0001-9635-4169',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Daniela Egas Santander',
    last_name: 'Egas Santander',
    ORCID: null,
    google_scholar: 'TlA2QzYAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Hubert Eichner',
    last_name: 'Eichner',
    ORCID: null,
    google_scholar: 'nYrg2f8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'S Eilemann',
    last_name: 'Eilemann',
    ORCID: null,
    google_scholar: 'H0hHPysAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Gaute T. Einevoll',
    last_name: 'Einevoll',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ana Belen Elgoyhen',
    last_name: 'Elgoyhen',
    ORCID: null,
    google_scholar: 'nmiWL74AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Vishalini Emmenegger',
    last_name: 'Emmenegger',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Håkon Enger',
    last_name: 'Enger',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jochen Martin Eppler',
    last_name: 'Eppler',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Olivia Eriksson',
    last_name: 'Eriksson',
    ORCID: null,
    google_scholar: '8RaQANcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Csaba Erö',
    last_name: 'Erö',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Alan Evans',
    last_name: 'Evans',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Timothée Ewart',
    last_name: 'Ewart',
    ORCID: null,
    google_scholar: 'XTsuhxoAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Paul Expert',
    last_name: 'Expert',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Guy Eyal',
    last_name: 'Eyal',
    ORCID: '0000-0002-9537-5571',
    google_scholar: 'E7kzEt4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alfonso Fairén',
    last_name: 'Fairén',
    ORCID: null,
    google_scholar: '8GdYeyUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joanne Falck',
    last_name: 'Falck',
    ORCID: null,
    google_scholar: 'K8pAUkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Egidio Falotico',
    last_name: 'Falotico',
    ORCID: '0000-0001-8060-8080',
    google_scholar: 'k1aWjf0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Xue Fan',
    last_name: 'Fan',
    ORCID: '0000-0003-2059-3926',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Silvia Farcito',
    last_name: 'Farcito',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Cyrille Pierre Henri Favreau',
    last_name: 'Favreau',
    ORCID: '0000-0003-1381-1585',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Johannes Feldmann',
    last_name: 'Feldmann',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Dirk Feldmeyer',
    last_name: 'Feldmeyer',
    ORCID: '0000-0002-1716-8972',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Joachim Fenkes',
    last_name: 'Fenkes',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Carlos Fernandez Musoles',
    last_name: 'Fernandez Musoles',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andre Ferreira Castro',
    last_name: 'Ferreira Castro',
    ORCID: null,
    google_scholar: 'pV1LeFUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Barbra Ferrell',
    last_name: 'Ferrell',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gianluca Ficarelli',
    last_name: 'Ficarelli',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Marko Filipović',
    last_name: 'Filipović',
    ORCID: '0000-0002-5801-1437',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Anna Fischer',
    last_name: 'Fischer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gord Fishell',
    last_name: 'Fishell',
    ORCID: null,
    google_scholar: 'eTJT9NAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'B. G. Fitch',
    last_name: 'Fitch',
    ORCID: null,
    google_scholar: 'RHycQGcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David Fitzpatrick',
    last_name: 'Fitzpatrick',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shane T. Fleming',
    last_name: 'Fleming',
    ORCID: null,
    google_scholar: 'bGjqBxIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Adrien Christian Fleury',
    last_name: 'Fleury',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jean-Claude Florent',
    last_name: 'Florent',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juliane Fluck',
    last_name: 'Fluck',
    ORCID: '0000-0003-1379-7023',
    google_scholar: 'LGfBcwsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alessandro Foni',
    last_name: 'Foni',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Pierre-Alexandre Fonta',
    last_name: 'Fonta',
    ORCID: null,
    google_scholar: 'dDVBCr8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Emanuele Formento',
    last_name: 'Formento',
    ORCID: null,
    google_scholar: 'sJt5aCEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jeremy Fouriaux',
    last_name: 'Fouriaux',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Richard Frackowiak',
    last_name: 'Frackowiak',
    ORCID: null,
    google_scholar: '4nu1zCsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ramon Fraga Pereira',
    last_name: 'Fraga Pereira',
    ORCID: null,
    google_scholar: '9HRSVRgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michele M. Franceschini',
    last_name: 'Franceschini',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Laurent Francioli',
    last_name: 'Francioli',
    ORCID: null,
    google_scholar: 'zChThWEAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Alexandre R. Franco',
    last_name: 'Franco',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yves Fregnac',
    last_name: 'Fregnac',
    ORCID: null,
    google_scholar: 'S1vqJ6YAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tamas F. Freund',
    last_name: 'Freund',
    ORCID: null,
    google_scholar: 'VTwMv7YAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Caroline Seligman Froehlich',
    last_name: 'Froehlich',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pascal Fua',
    last_name: 'Fua',
    ORCID: null,
    google_scholar: 'kzFmAkYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Steve Furber',
    last_name: 'Furber',
    ORCID: null,
    google_scholar: 'jLnsiBEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michele Gaio',
    last_name: 'Gaio',
    ORCID: null,
    google_scholar: 'C_EBGi8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eyal Gal',
    last_name: 'Gal',
    ORCID: '0000-0001-8140-9948',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Roberto Galeazzi',
    last_name: 'Galeazzi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Socorro Gama-Castro',
    last_name: 'Gama-Castro',
    ORCID: null,
    google_scholar: 'TOnkvWsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Luca Gambazzi',
    last_name: 'Gambazzi',
    ORCID: null,
    google_scholar: 'eDuL6sAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tobias Gänswein',
    last_name: 'Gänswein',
    ORCID: null,
    google_scholar: '6FoOR5EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Haifei Gao',
    last_name: 'Gao',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juan José García',
    last_name: 'García',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juan José Garcia Cantero',
    last_name: 'Garcia Cantero',
    ORCID: null,
    google_scholar: 'iYyRcDAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'María García-Amado',
    last_name: 'García-Amado',
    ORCID: '0000-0001-6778-5680',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Gardner',
    last_name: 'Gardner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Esther P. Gardner',
    last_name: 'Gardner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adélie Garin',
    last_name: 'Garin',
    ORCID: null,
    google_scholar: 'fNq0JzcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eduardo Garrido',
    last_name: 'Garrido',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marta Garcia-Gasulla',
    last_name: 'Gasulla',
    ORCID: null,
    google_scholar: 't3VczyIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Henry Genet',
    last_name: 'Genet',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Nancy George',
    last_name: 'George',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andrew J. Gerber',
    last_name: 'Gerber',
    ORCID: null,
    google_scholar: 'wfVlC6cAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Robert S. Germain',
    last_name: 'Germain',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'W. Gerstner',
    last_name: 'Gerstner',
    ORCID: null,
    google_scholar: 'vSd2RnEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Pavlo Getta',
    last_name: 'Getta',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Michael E. Gevaert',
    last_name: 'Gevaert',
    ORCID: '0000-0002-7547-3297',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Marc-Olivier Gewaltig',
    last_name: 'Gewaltig',
    ORCID: null,
    google_scholar: 'Dy-lNyAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jean-Pierre Ghobril',
    last_name: 'Ghobril',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Satrajit S. Ghosh',
    last_name: 'Ghosh',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Elisabetta Giacalone',
    last_name: 'Giacalone',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Albert Gidon',
    last_name: 'Gidon',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Miguel Gila',
    last_name: 'Gila',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Thomas H. Gillespie',
    last_name: 'Gillespie',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Todd Gillette',
    last_name: 'Gillette',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Matthieu Girod',
    last_name: 'Girod',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michele Giugliano',
    last_name: 'Giugliano',
    ORCID: null,
    google_scholar: 'ImrD5VwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Georgios Gkoutos',
    last_name: 'Gkoutos',
    ORCID: '0000-0002-2061-091X',
    google_scholar: 'zKrJjA0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Padraig Gleeson',
    last_name: 'Gleeson',
    ORCID: '0000-0001-5963-8576',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Amir Globerson',
    last_name: 'Globerson',
    ORCID: null,
    google_scholar: '5JserkUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Katharina Glomb',
    last_name: 'Glomb',
    ORCID: null,
    google_scholar: '82p3zIkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jesse H. Goldberg',
    last_name: 'Goldberg',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Patricia S Goldman-Rakic',
    last_name: 'Goldman-Rakic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Guillermo González-Burgos',
    last_name: 'González-Burgos',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Dan F. M. Goodman',
    last_name: 'Goodman',
    ORCID: null,
    google_scholar: '2HiNqI4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Philip H Goodman',
    last_name: 'Goodman',
    ORCID: null,
    google_scholar: 'gFCNF1sAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Natalia A Goriounova',
    last_name: 'Goriounova',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adam Gosztolai',
    last_name: 'Gosztolai',
    ORCID: null,
    google_scholar: 'fVGCjMsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas J. Grabowski',
    last_name: 'Grabowski',
    ORCID: null,
    google_scholar: 'pkPMYDIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joe W. Graham',
    last_name: 'Graham',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Sergey L. Gratiy',
    last_name: 'Gratiy',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Graupner',
    last_name: 'Graupner',
    ORCID: null,
    google_scholar: 'HkTh0pIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David B. Grayden',
    last_name: 'Grayden',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alessandra Griffa',
    last_name: 'Griffa',
    ORCID: null,
    google_scholar: 'vbgItmkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sten Grillner',
    last_name: 'Grillner',
    ORCID: null,
    google_scholar: '17T_GKUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Amiram Grinvald',
    last_name: 'Grinvald',
    ORCID: '0000-0002-3391-4445',
    google_scholar: 'jl5mgKsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Henning Groenda',
    last_name: 'Groenda',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Julie Grollier',
    last_name: 'Grollier',
    ORCID: null,
    google_scholar: 'eAgYj1IAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lukas N. Groschner',
    last_name: 'Groschner',
    ORCID: null,
    google_scholar: '0Nk1ECQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sonja Grün',
    last_name: 'Grün',
    ORCID: null,
    google_scholar: 'b4DhWyQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lin Gu',
    last_name: 'Gu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nadir Román Guerrero',
    last_name: 'Guerrero',
    ORCID: null,
    google_scholar: 'EketricAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Renzo Guerrini',
    last_name: 'Guerrini',
    ORCID: '0000-0002-7272-7079',
    google_scholar: 'ZhiXnioAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Idris Guessous',
    last_name: 'Guessous',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Romain Guiet',
    last_name: 'Guiet',
    ORCID: null,
    google_scholar: 'GRN4KpoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yike Guo',
    last_name: 'Guo',
    ORCID: null,
    google_scholar: '-0q6cIYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anirudh Gupta',
    last_name: 'Gupta',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Luc Guyot',
    last_name: 'Guyot',
    ORCID: null,
    google_scholar: 'tXQErVAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Markus Hadwiger',
    last_name: 'Hadwiger',
    ORCID: null,
    google_scholar: 'wmx7KVUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Valentin Haenel',
    last_name: 'Haenel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Olivier Hagens',
    last_name: 'Hagens',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Georg Hager',
    last_name: 'Hager',
    ORCID: null,
    google_scholar: 'ARurmwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joachim Hagger',
    last_name: 'Hagger',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Patric Hagmann',
    last_name: 'Hagmann',
    ORCID: null,
    google_scholar: 'vHwHpawAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Geir Halnes',
    last_name: 'Halnes',
    ORCID: null,
    google_scholar: '68xilNsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Philip de Witt-Hamer',
    last_name: 'Hamer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yunyun Han',
    last_name: 'Han',
    ORCID: '0000-0003-2336-167X',
    google_scholar: 'k0sfuEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kenneth Harris',
    last_name: 'Harris',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Janna Hastings',
    last_name: 'Hastings',
    ORCID: null,
    google_scholar: 'cz-hhPUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Robert John Hatch',
    last_name: 'Hatch',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Hausser',
    last_name: 'Hausser',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Hawrylycz',
    last_name: 'Hawrylycz',
    ORCID: null,
    google_scholar: 'xWhtmvAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Etay Hay',
    last_name: 'Hay',
    ORCID: null,
    google_scholar: 'iircviMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hai-yan He',
    last_name: 'He',
    ORCID: null,
    google_scholar: 'rjuSy1EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Heinis',
    last_name: 'Heinis',
    ORCID: null,
    google_scholar: 'yJ998VYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anibal Sólon Heinsfeld',
    last_name: 'Heinsfeld',
    ORCID: '0000-0002-2050-0614',
    google_scholar: 'gQdfws4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tim S Heistek',
    last_name: 'Heistek',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Daniela Helbig',
    last_name: 'Helbig',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jeanette Hellgren Kotaleski',
    last_name: 'Hellgren Kotaleski',
    ORCID: null,
    google_scholar: '55D57zgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Moritz Helmstaedter',
    last_name: 'Helmstaedter',
    ORCID: null,
    google_scholar: 'MPE5hLMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Timothé Guyonnet-Hencke',
    last_name: 'Hencke',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Iain Hepburn',
    last_name: 'Hepburn',
    ORCID: null,
    google_scholar: 'haevS-4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Juan B. Hernando',
    last_name: 'Hernando',
    ORCID: '0000-0002-8562-8213',
    google_scholar: 'ZvJW-b4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joni Herttuainen',
    last_name: 'Herttuainen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Andreas V. M. Herz',
    last_name: 'Herz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mirjia Herzog',
    last_name: 'Herzog',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Kathryn Hess',
    last_name: 'Hess',
    ORCID: '0000-0003-2788-9754',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shaul Hestrin',
    last_name: 'Hestrin',
    ORCID: null,
    google_scholar: 'jrCs_EEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rolf-Dieter Heuer',
    last_name: 'Heuer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Djai B Heyer',
    last_name: 'Heyer',
    ORCID: null,
    google_scholar: 'KSQMB5QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Robert J. Hickey',
    last_name: 'Hickey',
    ORCID: null,
    google_scholar: 'ThxoTNUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andreas Hierlemann',
    last_name: 'Hierlemann',
    ORCID: null,
    google_scholar: 'qbNrSPYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sean L. Hill',
    last_name: 'Hill',
    ORCID: '0000-0001-8055-860X',
    google_scholar: 'dnK5QYMAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Michael L. Hines',
    last_name: 'Hines',
    ORCID: '0009-0002-2976-7910',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Georg Hinkel',
    last_name: 'Hinkel',
    ORCID: '0000-0002-6462-5208',
    google_scholar: 'yXy_x_wAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lynette Hirschman',
    last_name: 'Hirschman',
    ORCID: null,
    google_scholar: 'BSY62VAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Patrick R. Hof',
    last_name: 'Hof',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sune Holm',
    last_name: 'Holm',
    ORCID: null,
    google_scholar: 'AKwqbTIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anthony Holtmaat',
    last_name: 'Holtmaat',
    ORCID: null,
    google_scholar: 'RnLdpNgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Houman Honaryar',
    last_name: 'Honaryar',
    ORCID: null,
    google_scholar: 'vonZZnkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michael W. Hopkins',
    last_name: 'Hopkins',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Illia Horenko',
    last_name: 'Horenko',
    ORCID: null,
    google_scholar: 'nkpzW44AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anna-Lena Horlemann',
    last_name: 'Horlemann',
    ORCID: null,
    google_scholar: '9uICMD8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Morteza Hosseini',
    last_name: 'Hosseini',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Josh Huang',
    last_name: 'Huang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Manuel Huber',
    last_name: 'Huber',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Richard Huganir',
    last_name: 'Huganir',
    ORCID: null,
    google_scholar: '0WhuKSwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sarah Hunt',
    last_name: 'Hunt',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'R. Matthew Hutchison',
    last_name: 'Hutchison',
    ORCID: null,
    google_scholar: 'aggJETIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Giulio Iannello',
    last_name: 'Iannello',
    ORCID: '0000-0003-3864-5800',
    google_scholar: 'L-UJxIgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Maxim Iascone',
    last_name: 'Iascone',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Georgios Iatropoulos',
    last_name: 'Iatropoulos',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Elisabetta Iavarone',
    last_name: 'Iavarone',
    ORCID: '0000-0001-5157-247X',
    google_scholar: 'i_mWV5cAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Sander Idema',
    last_name: 'Idema',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Giovanni Idili',
    last_name: 'Idili',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Daniele Ielmini',
    last_name: 'Ielmini',
    ORCID: null,
    google_scholar: 'DHFCB_oAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Auke Jan Ijspeert',
    last_name: 'Ijspeert',
    ORCID: '0000-0003-1417-9980',
    google_scholar: 'ViYx9vMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lital Bar Ilan',
    last_name: 'Ilan',
    ORCID: null,
    google_scholar: 't7m-b5AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Milo Imbeni',
    last_name: 'Imbeni',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Giacomo Indiveri',
    last_name: 'Indiveri',
    ORCID: null,
    google_scholar: 'kdHjCAMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Cédric F. Invernizzi',
    last_name: 'Invernizzi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Samuel Inverso',
    last_name: 'Inverso',
    ORCID: null,
    google_scholar: 'RKvrOZ8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'John Hjort Ipsen',
    last_name: 'Ipsen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Afroza K. Irin',
    last_name: 'Irin',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'James B Isbister',
    last_name: 'Isbister',
    ORCID: ' 0000-0002-1013-3013',
    google_scholar: 'CFhtPfwAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Kei Ito',
    last_name: 'Ito',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Genrich Ivaska',
    last_name: 'Ivaska',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Chad Jackson',
    last_name: 'Jackson',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Viren Jain',
    last_name: 'Jain',
    ORCID: null,
    google_scholar: 'vXqLMtkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Muhammad Asif Jan',
    last_name: 'Jan',
    ORCID: null,
    google_scholar: 'F6-L_GUAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jakub Jancovic',
    last_name: 'Jancovic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Georges Abou Jaoudé',
    last_name: 'Jaoudé',
    ORCID: null,
    google_scholar: 'u7TRtooAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Aurélien Tristan Jaquier',
    last_name: 'Jaquier',
    ORCID: '0000-0001-6202-6175',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Gregory Jefferis',
    last_name: 'Jefferis',
    ORCID: null,
    google_scholar: 'cuXoCA8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kim E. Jelfs',
    last_name: 'Jelfs',
    ORCID: null,
    google_scholar: 'r3IdtREAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lars J. Jensen',
    last_name: 'Jensen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shuiwang Ji',
    last_name: 'Ji',
    ORCID: null,
    google_scholar: 'BZGj6sAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Weina Ji',
    last_name: 'Ji',
    ORCID: '0009-0005-1818-4762',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Xiaoxuan Jia',
    last_name: 'Jia',
    ORCID: null,
    google_scholar: '7waL2dAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Silvia Jimenez',
    last_name: 'Jimenez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Viktor Jirsa',
    last_name: 'Jirsa',
    ORCID: '0000-0002-8251-8860',
    google_scholar: '0ZVdLpMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ludger Johannes',
    last_name: 'Johannes',
    ORCID: '0000-0002-2168-0004',
    google_scholar: '8QZVKTEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Niklaus Johner',
    last_name: 'Johner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Katherine Gianan Johnston',
    last_name: 'Johnston',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Renaud Jolivet',
    last_name: 'Jolivet',
    ORCID: '0000-0002-5167-0851',
    google_scholar: '9Ozwv7EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Edward G. Jones',
    last_name: 'Jones',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adrien Journe',
    last_name: 'Journe',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Meizhi Ju',
    last_name: 'Ju',
    ORCID: null,
    google_scholar: 'UzFB-d8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Toni R. Jue',
    last_name: 'Jue',
    ORCID: null,
    google_scholar: 'G_PxupsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Guy Antoine Atenekeng Kahou',
    last_name: 'Kahou',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jacques Kaiser',
    last_name: 'Kaiser',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Szabolcs Káli',
    last_name: 'Káli',
    ORCID: null,
    google_scholar: '8gykEqQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Mira Marcus-Kalish',
    last_name: 'Kalish',
    ORCID: null,
    google_scholar: '1r1OcSAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin Kalloch',
    last_name: 'Kalloch',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Brian Kalmbach',
    last_name: 'Kalmbach',
    ORCID: null,
    google_scholar: 'QL_C4hkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'P. Kaltstein',
    last_name: 'Kaltstein',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Lida Kanari',
    last_name: 'Kanari',
    ORCID: '0000-0002-9539-5070',
    google_scholar: 'tFiySncAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Eric R. Kandel',
    last_name: 'Kandel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christina Kapatsori',
    last_name: 'Kapatsori',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Kalpana Kare',
    last_name: 'Kare',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jonas Karlsson',
    last_name: 'Karlsson',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Mahesh M Karnani',
    last_name: 'Karnani',
    ORCID: null,
    google_scholar: 'OS9t6g4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Fuyuki Karube',
    last_name: 'Karube',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Rob Kass',
    last_name: 'Kass',
    ORCID: null,
    google_scholar: 'zZz9HIwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bobby Kasthuri',
    last_name: 'Kasthuri',
    ORCID: null,
    google_scholar: 'yrazx4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Claudia Kathe',
    last_name: 'Kathe',
    ORCID: null,
    google_scholar: 'I_8VhKgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Yury Katkov',
    last_name: 'Katkov',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Anna-Kristin Kaufmann',
    last_name: 'Kaufmann',
    ORCID: null,
    google_scholar: 'f7AJchcAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Yasuo Kawaguchi',
    last_name: 'Kawaguchi',
    ORCID: '0000-0002-7483-8362',
    google_scholar: 'KuO7ZP4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kinga Kazala',
    last_name: 'Kazala',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Liam Keegan',
    last_name: 'Keegan',
    ORCID: null,
    google_scholar: 'gNxnFsAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Scott T Keene',
    last_name: 'Keene',
    ORCID: null,
    google_scholar: 'pZhsx58AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Keller',
    last_name: 'Keller',
    ORCID: '0000-0003-3280-6255',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'John Kenyon',
    last_name: 'Kenyon',
    ORCID: null,
    google_scholar: 'z860bHQAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Samuel Kerrien',
    last_name: 'Kerrien',
    ORCID: null,
    google_scholar: 'Pt7Kz6cAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Ingrid Keseler',
    last_name: 'Keseler',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Maya Ketzef',
    last_name: 'Ketzef',
    ORCID: '0000-0003-3177-0430',
    google_scholar: 'GkZAA-8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Georges Khazen',
    last_name: 'Khazen',
    ORCID: null,
    google_scholar: 'KUeLGQMAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Gregory Kiar',
    last_name: 'Kiar',
    ORCID: null,
    google_scholar: 'ztw6g7kAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ilkan Fabrice Kiliç',
    last_name: 'Kiliç',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jinhyun Kim',
    last_name: 'Kim',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yihwa Kim',
    last_name: 'Kim',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Youngeun Kim',
    last_name: 'Kim',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'James Gonzalo King',
    last_name: 'King',
    ORCID: '0000-0003-0906-8389',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Murat Kirtay',
    last_name: 'Kirtay',
    ORCID: null,
    google_scholar: 'Pvkf_ksAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Zoltán F. Kisvárday',
    last_name: 'Kisvárday',
    ORCID: null,
    google_scholar: 'jxSJPdAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'A. Klimentov',
    last_name: 'Klimentov',
    ORCID: null,
    google_scholar: 'Qjq2Ya0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'G. Klinker',
    last_name: 'Klinker',
    ORCID: null,
    google_scholar: 'NJreCkoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'James C. Knight',
    last_name: 'Knight',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alois C. Knoll',
    last_name: 'Knoll',
    ORCID: '0000-0003-4840-076X',
    google_scholar: 'CA8QgwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas R. Knösche',
    last_name: 'Knösche',
    ORCID: null,
    google_scholar: 'VH2U9XEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Graham W Knott',
    last_name: 'Knott',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christof Koch',
    last_name: 'Koch',
    ORCID: '0000-0001-6482-8067',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Olexiy Kochubey',
    last_name: 'Kochubey',
    ORCID: '0000-0002-8115-7733',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Konrad Kording',
    last_name: 'Kording',
    ORCID: null,
    google_scholar: 'MiFqJGcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christos Kotsalos',
    last_name: 'Kotsalos',
    ORCID: null,
    google_scholar: 'FrOwAVIAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Sandhya Koushika',
    last_name: 'Koushika',
    ORCID: null,
    google_scholar: 'c4zDSQ8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'J. Kozloski',
    last_name: 'Kozloski',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alexander K. Kozlov',
    last_name: 'Kozlov',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sebastian Krach',
    last_name: 'Krach',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'John Krakauer',
    last_name: 'Krakauer',
    ORCID: null,
    google_scholar: 'hcZrVwYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andrei Kramer',
    last_name: 'Kramer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ivo Krejci',
    last_name: 'Krejci',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jan Krepl',
    last_name: 'Krepl',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Birgit Kriener',
    last_name: 'Kriener',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yoshiyuki Kubota',
    last_name: 'Kubota',
    ORCID: '0000-0002-0950-7460',
    google_scholar: 'ICZ2rVIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Arvind Kumar',
    last_name: 'Kumar',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sameer Kumar',
    last_name: 'Kumar',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pramod S. Kumbhar',
    last_name: 'Kumbhar',
    ORCID: '0000-0002-1756-801X',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Prantik Kundu',
    last_name: 'Kundu',
    ORCID: null,
    google_scholar: 'kSXl63oAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rüdiger Kupper',
    last_name: 'Kupper',
    ORCID: null,
    google_scholar: 'LgWPSecAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ihor Kuras',
    last_name: 'Kuras',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Kerem Kurban',
    last_name: 'Kurban',
    ORCID: null,
    google_scholar: 'Sq8ag8MAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Niels Kuster',
    last_name: 'Kuster',
    ORCID: null,
    google_scholar: 'O5_DVpYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: "Thibaud L'Yvonnet",
    last_name: "L'Yvonnet",
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Clément Lagisquet',
    last_name: 'Lagisquet',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Angela R. Laird',
    last_name: 'Laird',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jules Lallouette',
    last_name: 'Lallouette',
    ORCID: null,
    google_scholar: 'rAov98cAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bertrand Lambolez',
    last_name: 'Lambolez',
    ORCID: null,
    google_scholar: 'XVcZSVsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jacob A. LaNasa',
    last_name: 'LaNasa',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Story Landis',
    last_name: 'Landis',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Zachary C. Landry',
    last_name: 'Landry',
    ORCID: null,
    google_scholar: 'lvutsTUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sigrun Lange',
    last_name: 'Lange',
    ORCID: '0000-0002-7193-3102',
    google_scholar: 'lMdQqaAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kai Langen',
    last_name: 'Langen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Frederic Lanore',
    last_name: 'Lanore',
    ORCID: '0000-0003-4316-6855',
    google_scholar: 'zelvcGkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Mario Lanza',
    last_name: 'Lanza',
    ORCID: null,
    google_scholar: 'UMtFllgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Samuel Lapere',
    last_name: 'Lapere',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Steeve Laquitaine',
    last_name: 'Laquitaine',
    ORCID: null,
    google_scholar: 'ftTg_S4AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Pedro Larrañaga',
    last_name: 'Larrañaga',
    ORCID: '0000-0003-0652-9872',
    google_scholar: 'iTlD56SV03AC',
    'bbp-member': false,
  },
  {
    full_name: 'Stephen D. Larson',
    last_name: 'Larson',
    ORCID: null,
    google_scholar: 'FijBSP8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'C. Laschi',
    last_name: 'Laschi',
    ORCID: null,
    google_scholar: '1vR7lMUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hilal A. Lashuel',
    last_name: 'Lashuel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sébastien Lasserre',
    last_name: 'Lasserre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Stanley J. F. Laulederkind',
    last_name: 'Laulederkind',
    ORCID: null,
    google_scholar: 'u8jZquEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ian Lavriushev',
    last_name: 'Lavriushev',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jeff Layton',
    last_name: 'Layton',
    ORCID: null,
    google_scholar: 'ggmSpcIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jānis Lazovskis',
    last_name: 'Lazovskis',
    ORCID: null,
    google_scholar: '2WYCcHgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jean-Vincent Le Bé',
    last_name: 'Le Bé',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Manuel Le Gallo',
    last_name: 'Le Gallo',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Brian Lee',
    last_name: 'Lee',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Soo Yeun Lee',
    last_name: 'Lee',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin De Leener',
    last_name: 'Leener',
    ORCID: null,
    google_scholar: 'hXOe8XkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Trygve B. Leergaard',
    last_name: 'Leergaard',
    ORCID: '0000-0001-5965-8470',
    google_scholar: 'w-sc1loAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Heikki Lehväslaiho',
    last_name: 'Lehväslaiho',
    ORCID: '0000-0002-6263-1356',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yoni Leibner',
    last_name: 'Leibner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ed S Lein',
    last_name: 'Lein',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Florian Leitner',
    last_name: 'Leitner',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David R. Lester',
    last_name: 'Lester',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juliana Leung',
    last_name: 'Leung',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Paul Levi',
    last_name: 'Levi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ran Levi',
    last_name: 'Levi',
    ORCID: '0000-0001-5297-8295',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David A. Lewis',
    last_name: 'Lewis',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Maolin Li',
    last_name: 'Li',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Xiang Li',
    last_name: 'Li',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Xuan Li',
    last_name: 'Li',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yujie Li',
    last_name: 'Li',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shi-Jun Liang',
    last_name: 'Liang',
    ORCID: null,
    google_scholar: 'iXFEftUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Fabio Librizzi',
    last_name: 'Librizzi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sook-Lei Liew',
    last_name: 'Liew',
    ORCID: null,
    google_scholar: 'wRHMqzsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Mikael Lindahl',
    last_name: 'Lindahl',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Carolina Lindqvist',
    last_name: 'Lindqvist',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Robert Lindroos',
    last_name: 'Lindroos',
    ORCID: '0000-0002-9134-3601',
    google_scholar: 'jc4A520AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marja-Leena Linne',
    last_name: 'Linne',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Polina Litvak',
    last_name: 'Litvak',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'ShihChii Liu',
    last_name: 'Liu',
    ORCID: null,
    google_scholar: 'XYkPvZUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Siqi Liu',
    last_name: 'Liu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Tianming Liu',
    last_name: 'Liu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Xiaoxiao Liu',
    last_name: 'Liu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yufeng Liu',
    last_name: 'Liu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Rick Livesey',
    last_name: 'Livesey',
    ORCID: null,
    google_scholar: 'OKxZl5AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bryn Lloyd',
    last_name: 'Lloyd',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Johannes C Lodder',
    last_name: 'Lodder',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'A. Lodi-Rizzini',
    last_name: 'Lodi-Rizzini',
    ORCID: null,
    google_scholar: 'SI2Hzh8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alex Loebel',
    last_name: 'Loebel',
    ORCID: null,
    google_scholar: 'bsiANeEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Emmanuelle Logette',
    last_name: 'Logette',
    ORCID: '0000-0002-9229-3503',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Michael London',
    last_name: 'London',
    ORCID: '0000-0001-5137-1707',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pedro L. López-Cruz',
    last_name: 'López-Cruz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Charlotte Lorin',
    last_name: 'Lorin',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Huanxiang Lu',
    last_name: 'Lu',
    ORCID: '0000-0003-3377-9359',
    google_scholar: 'yo8K1SoAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Zhiyong Lu',
    last_name: 'Lu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Maxime Lucas',
    last_name: 'Lucas',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Heiko J. Luhmann',
    last_name: 'Luhmann',
    ORCID: null,
    google_scholar: 'FDch3OMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Qingming Luo',
    last_name: 'Luo',
    ORCID: null,
    google_scholar: 'L5PU240AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Carmen A. Lupascu',
    last_name: 'Lupascu',
    ORCID: null,
    google_scholar: 'Fixq-mQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Olli Lupton',
    last_name: 'Lupton',
    ORCID: null,
    google_scholar: 'OXI_K0MAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Daniel J. Lurie',
    last_name: 'Lurie',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jonathan Lurie',
    last_name: 'Lurie',
    ORCID: null,
    google_scholar: 'mu5aEXYAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Patrycja Lurie',
    last_name: 'Lurie',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'W. W. Lytton',
    last_name: 'Lytton',
    ORCID: null,
    google_scholar: 'yTK0o2UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Junying Ma',
    last_name: 'Ma',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Wolfgang Maass',
    last_name: 'Maass',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Julien Machon',
    last_name: 'Machon',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Sumit Madan',
    last_name: 'Madan',
    ORCID: null,
    google_scholar: 'MDPDw3QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bruno Ricardo Da Cunha Magalhães',
    last_name: 'Magalhães',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pierre Julius Magistretti',
    last_name: 'Magistretti',
    ORCID: '0000-0002-6678-320X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ioannis Magkanaris',
    last_name: 'Magkanaris',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Mufti Mahmud',
    last_name: 'Mahmud',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Patrick Maier',
    last_name: 'Maier',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Amitava Majumdar',
    last_name: 'Majumdar',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'M. Makhinya',
    last_name: 'Makhinya',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Tuomo Mäki-Marttunen',
    last_name: 'Mäki-Marttunen',
    ORCID: '0000-0002-7082-2507',
    google_scholar: '_s1J6DgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Brendan Mallery',
    last_name: 'Mallery',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Damian A. Mallon',
    last_name: 'Mallon',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Dhanesh Neela Mana',
    last_name: 'Mana',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alekhya Mandali',
    last_name: 'Mandali',
    ORCID: null,
    google_scholar: 'FXAVnucAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Darshan Mandge',
    last_name: 'Mandge',
    ORCID: null,
    google_scholar: 'uwcvxTYAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Huibert D. Mansvelder',
    last_name: 'Mansvelder',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Filippo Mantovani',
    last_name: 'Mantovani',
    ORCID: null,
    google_scholar: '2H-yxNkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Linus Manubens-Gil',
    last_name: 'Manubens-Gil',
    ORCID: null,
    google_scholar: 'djj1IYUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michela Marani',
    last_name: 'Marani',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adam Marblestone',
    last_name: 'Marblestone',
    ORCID: null,
    google_scholar: 'pRTuNPsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel S. Margulies',
    last_name: 'Margulies',
    ORCID: null,
    google_scholar: 'al10sgYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bóris Marin',
    last_name: 'Marin',
    ORCID: '0000-0001-5503-648X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Oscar Marín',
    last_name: 'Marín',
    ORCID: '0000-0001-6264-7027',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sergey N. Markin',
    last_name: 'Markin',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Danijela Marković',
    last_name: 'Marković',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David Markowitz',
    last_name: 'Markowitz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Henry Markram',
    last_name: 'Markram',
    ORCID: null,
    google_scholar: 'W3lyJF8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alejandra Garcia Rojas Martinez',
    last_name: 'Martinez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pablo Martínez-Cañada',
    last_name: 'Martínez-Cañada',
    ORCID: '0000-0003-2634-5229',
    google_scholar: 'FNb-uCQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Clara Martínez-Pérez',
    last_name: 'Martínez-Pérez',
    ORCID: '0000-0002-9600-3331',
    google_scholar: 'BpAJUsUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Maryann E. Martone',
    last_name: 'Martone',
    ORCID: null,
    google_scholar: 'vSSW75cAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anna Letizia Allegra Mascaro',
    last_name: 'Mascaro',
    ORCID: null,
    google_scholar: 'UqLxxOoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'R. Mashinistov',
    last_name: 'Mashinistov',
    ORCID: null,
    google_scholar: 'GOJqDJ8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stefano Masoli',
    last_name: 'Masoli',
    ORCID: '0000-0003-0660-9952',
    google_scholar: 'qb8LxFwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hansruedi Mathys',
    last_name: 'Mathys',
    ORCID: null,
    google_scholar: 'n-C3oCQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sherri Matis-Mitchell',
    last_name: 'Matis-Mitchell',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sérgio Matos',
    last_name: 'Matos',
    ORCID: '0000-0003-1941-3983',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Paul M. Matthews',
    last_name: 'Matthews',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Arnab Neelim Mazumder',
    last_name: 'Mazumder',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Justin McArthur',
    last_name: 'McArthur',
    ORCID: null,
    google_scholar: 'HweyCIYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Chris J. McBain',
    last_name: 'McBain',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Robert A. McDougal',
    last_name: 'McDougal',
    ORCID: null,
    google_scholar: 'voD3OosAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Johanna McEntyre',
    last_name: 'McEntyre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Donald G. McLaren',
    last_name: 'McLaren',
    ORCID: null,
    google_scholar: 'BcMyFPIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'John McNaught',
    last_name: 'McNaught',
    ORCID: null,
    google_scholar: 'MSKpy6gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Peter McQuilton',
    last_name: 'McQuilton',
    ORCID: '0000-0003-2687-1982',
    google_scholar: 'Mi4BwxsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Erik Meijering',
    last_name: 'Meijering',
    ORCID: null,
    google_scholar: 'MsX349AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ofer Melamed',
    last_name: 'Melamed',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Samuel Melchior',
    last_name: 'Melchior',
    ORCID: null,
    google_scholar: 'oiX5-TkAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Nathalie Mella',
    last_name: 'Mella',
    ORCID: null,
    google_scholar: 'm0DBuiIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Felipe Meneguzzi',
    last_name: 'Meneguzzi',
    ORCID: null,
    google_scholar: 'uFEbojwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Maarten Mennes',
    last_name: 'Mennes',
    ORCID: '0000-0002-7279-3439',
    google_scholar: 'pLlSTVgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Brett Mensh',
    last_name: 'Mensh',
    ORCID: null,
    google_scholar: 'FFAa6W8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stephan Menzel',
    last_name: 'Menzel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Audrey Mercer',
    last_name: 'Mercer',
    ORCID: '0000-0002-5086-2560',
    google_scholar: 'mgp3JaIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Angel Merchán-Pérez',
    last_name: 'Merchán-Pérez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Eline J. Mertens',
    last_name: 'Mertens',
    ORCID: null,
    google_scholar: 'FBiTfAMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Salma Mesmoudi',
    last_name: 'Mesmoudi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'César M. J. A. Metzger',
    last_name: 'Metzger',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Bernard Metzler',
    last_name: 'Metzler',
    ORCID: null,
    google_scholar: 'FtL8mVIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hanno S. Meyer',
    last_name: 'Meyer',
    ORCID: '0000-0002-4576-1239',
    google_scholar: 'Pz75pIUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Julie Meystre',
    last_name: 'Meystre',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Feng Miao',
    last_name: 'Miao',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Silvestro Micera',
    last_name: 'Micera',
    ORCID: '0000-0003-4396-8217',
    google_scholar: '9kH6EEAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gero Miesenböck',
    last_name: 'Miesenböck',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michele Migliore',
    last_name: 'Migliore',
    ORCID: '0000-0002-7584-6292',
    google_scholar: 'PhiCG5kAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rosanna Migliore',
    last_name: 'Migliore',
    ORCID: '0000-0001-9034-7849',
    google_scholar: 'odf6GbEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bojan Mihaljević',
    last_name: 'Mihaljević',
    ORCID: null,
    google_scholar: 'o1ZNZlMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Mikolajick',
    last_name: 'Mikolajick',
    ORCID: null,
    google_scholar: 'oO0M3q4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marija Milacic',
    last_name: 'Milacic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gianluca Milano',
    last_name: 'Milano',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michael Milham',
    last_name: 'Milham',
    ORCID: null,
    google_scholar: 'N4-Y6GYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michael Miller',
    last_name: 'Miller',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mikhaél Minisini',
    last_name: 'Minisini',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'George Mitenkov',
    last_name: 'Mitenkov',
    ORCID: null,
    google_scholar: 'BvhRAegAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Partha Mitra',
    last_name: 'Mitra',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alice Mizrahi',
    last_name: 'Mizrahi',
    ORCID: null,
    google_scholar: 'O1AzDjoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tinoosh Mohsenin',
    last_name: 'Mohsenin',
    ORCID: null,
    google_scholar: '9grfK9gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Caitlin Monney',
    last_name: 'Monney',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Didac Montero Mendez',
    last_name: 'Montero Mendez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Hannah Monyer',
    last_name: 'Monyer',
    ORCID: '0000-0002-9332-5749',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Simon W. Moore',
    last_name: 'Moore',
    ORCID: null,
    google_scholar: '4YlZvscAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Juan Morales',
    last_name: 'Morales',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sebastien Morand',
    last_name: 'Morand',
    ORCID: null,
    google_scholar: 'JzFXIIkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christian Morillas',
    last_name: 'Morillas',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Peter Morjan',
    last_name: 'Morjan',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin Roy Morrice',
    last_name: 'Morrice',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Abigail Morrison',
    last_name: 'Morrison',
    ORCID: '0000-0001-6933-797X',
    google_scholar: 'D527oQ4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Matthew Mort',
    last_name: 'Mort',
    ORCID: null,
    google_scholar: '8DODdKYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Clayton P. Mosher',
    last_name: 'Mosher',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Vignayanandam Ravindernath Muddapu',
    last_name: 'Muddapu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Marie Caroline Müllenbroich',
    last_name: 'Müllenbroich',
    ORCID: '0000-0003-0113-8573',
    google_scholar: 'wyirXOsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eilif B. Muller',
    last_name: 'Muller',
    ORCID: null,
    google_scholar: 'r4-NZhwAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jeffrey Muller',
    last_name: 'Muller',
    ORCID: null,
    google_scholar: 'kkb7ZYYAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Eric Müller',
    last_name: 'Müller',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Emeline Mullier',
    last_name: 'Mullier',
    ORCID: null,
    google_scholar: '8t21Wc8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alberto Muñoz',
    last_name: 'Muñoz',
    ORCID: '0000-0003-4607-3398',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alberto Muñoz-Céspedes',
    last_name: 'Muñoz-Céspedes',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shruti Muralidhar',
    last_name: 'Muralidhar',
    ORCID: null,
    google_scholar: '0tDRCyQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Leila Muresan',
    last_name: 'Muresan',
    ORCID: null,
    google_scholar: 'o_K0ooEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Keerthan Muthurasa',
    last_name: 'Muthurasa',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Daniel Nachbaur',
    last_name: 'Nachbaur',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Anu G. Nair',
    last_name: 'Nair',
    ORCID: null,
    google_scholar: '3EpZi6IAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jayakrishnan Nair',
    last_name: 'Nair',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sumit Nanda',
    last_name: 'Nanda',
    ORCID: null,
    google_scholar: 't9m1XmsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anirban Nandi',
    last_name: 'Nandi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jeyakumar Natarajan',
    last_name: 'Natarajan',
    ORCID: null,
    google_scholar: 'sum_sT4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Richard Naud',
    last_name: 'Naud',
    ORCID: null,
    google_scholar: '2HXsblgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Matthew F. Naylor',
    last_name: 'Naylor',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Emre Neftci',
    last_name: 'Neftci',
    ORCID: null,
    google_scholar: 'yYT6jtkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Israel Nelken',
    last_name: 'Nelken',
    ORCID: null,
    google_scholar: '6_UmGu0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sacha B. Nelson',
    last_name: 'Nelson',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yosra Nemri',
    last_name: 'Nemri',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Aljoscha Nern',
    last_name: 'Nern',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pedja Neskovic',
    last_name: 'Neskovic',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Torbjørn V. Ness',
    last_name: 'Ness',
    ORCID: null,
    google_scholar: 'xeVr3rAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Esra Neufeld',
    last_name: 'Neufeld',
    ORCID: null,
    google_scholar: 'fW9e_UEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Nevian',
    last_name: 'Nevian',
    ORCID: '0000-0001-9804-608X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adam J. H. Newton',
    last_name: 'Newton',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Taylor H. Newton',
    last_name: 'Newton',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Wai Kit Ng',
    last_name: 'Ng',
    ORCID: null,
    google_scholar: 'CD5SFdMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lydia Ng',
    last_name: 'Ng',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Miguel Nicolelis',
    last_name: 'Nicolelis',
    ORCID: null,
    google_scholar: 'RefX_60AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin Torben-Nielsen',
    last_name: 'Nielsen',
    ORCID: '0000-0003-4203-9942',
    google_scholar: 'BpfFZ8MAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Nicolas Ninin',
    last_name: 'Ninin',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Zahra Niroobakhsh',
    last_name: 'Niroobakhsh',
    ORCID: null,
    google_scholar: '8QsAH_AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'B. Nolan Nichols',
    last_name: 'Nolan Nichols',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Max Nolte',
    last_name: 'Nolte',
    ORCID: '0000-0002-9452-4926',
    google_scholar: 'qGwEGKEAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'David P Noske',
    last_name: 'Noske',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Nowotny',
    last_name: 'Nowotny',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Angel Nuñez',
    last_name: 'Nuñez',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marco Nurisso',
    last_name: 'Nurisso',
    ORCID: null,
    google_scholar: 'vAaWpu8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: "Richard O'Brien",
    last_name: "O'Brien",
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: "Christian O'Reilly",
    last_name: "O'Reilly",
    ORCID: '0000-0002-3149-4934',
    google_scholar: 'NllRAkwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marcel Oberlaender',
    last_name: 'Oberlaender',
    ORCID: null,
    google_scholar: 'VFoPFi0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joshua Obermayer',
    last_name: 'Obermayer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Aude Oliva',
    last_name: 'Oliva',
    ORCID: null,
    google_scholar: 'FNhl50sAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Shawn Olsen',
    last_name: 'Olsen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Leonardo Onofri',
    last_name: 'Onofri',
    ORCID: '0000-0001-7305-0672',
    google_scholar: '3dOwNtQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gergo Orban',
    last_name: 'Orban',
    ORCID: null,
    google_scholar: '1JeMZad2LKcC',
    'bbp-member': false,
  },
  {
    full_name: 'Sandra Orchard',
    last_name: 'Orchard',
    ORCID: '0000-0002-8878-3972',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Eugenia Oshurko',
    last_name: 'Oshurko',
    ORCID: null,
    google_scholar: 'kHGyOJUAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Halina Oswald',
    last_name: 'Oswald',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Aleksandr Ovcharenko',
    last_name: 'Ovcharenko',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Adam Packer',
    last_name: 'Packer',
    ORCID: null,
    google_scholar: 'gH7eCXUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Evangelos Pafilis',
    last_name: 'Pafilis',
    ORCID: null,
    google_scholar: 'Aik8EvoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Renato Pajarola',
    last_name: 'Pajarola',
    ORCID: null,
    google_scholar: 'O-Zn7k4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Kadri Pajo',
    last_name: 'Pajo',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juan Palacios',
    last_name: 'Palacios',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Henry A. Palasciano',
    last_name: 'Palasciano',
    ORCID: null,
    google_scholar: 'l9q9xXsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Priyadarshini Panda',
    last_name: 'Panda',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gabriella Panuccio',
    last_name: 'Panuccio',
    ORCID: null,
    google_scholar: 'SLlTJrsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Erick H. Pasaye',
    last_name: 'Pasaye',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Axel Pasqualini',
    last_name: 'Pasqualini',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Maria Pasquini',
    last_name: 'Pasquini',
    ORCID: '0000-0001-6425-9167',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Luis Pastor',
    last_name: 'Pastor',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Francesco Saverio Pavone',
    last_name: 'Pavone',
    ORCID: null,
    google_scholar: 'P5iPu4AAAA',
    'bbp-member': false,
  },
  {
    full_name: 'Robert L. Peach',
    last_name: 'Peach',
    ORCID: null,
    google_scholar: '5vnU9VwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'François J. Peaudecerf',
    last_name: 'Peaudecerf',
    ORCID: '0000-0003-0295-4556',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Margit S. Peaudecerf',
    last_name: 'Peaudecerf',
    ORCID: '0000-0003-4243-8425',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'C. Peck',
    last_name: 'Peck',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Scott Peltier',
    last_name: 'Peltier',
    ORCID: null,
    google_scholar: 'cRxo2BUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hanchuan Peng',
    last_name: 'Peng',
    ORCID: null,
    google_scholar: 'mzHFPr4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Peppicelli',
    last_name: 'Peppicelli',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Emiliano Pereira',
    last_name: 'Pereira',
    ORCID: null,
    google_scholar: 'jbiibh4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Fernando L. Pereira',
    last_name: 'Pereira',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juan Pérez-Fernández',
    last_name: 'Pérez-Fernández',
    ORCID: '0000-0001-7109-951X',
    google_scholar: '2TRnJuwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Igor Peric',
    last_name: 'Peric',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Matthew Perich',
    last_name: 'Perich',
    ORCID: '0000-0001-9800-2386',
    google_scholar: 'tTUtLsQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rodrigo de Campos Perin',
    last_name: 'Perin',
    ORCID: null,
    google_scholar: '_EkSlfYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Wolfram H P Pernice',
    last_name: 'Pernice',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Manuel Peter',
    last_name: 'Peter',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Carl C. H. Petersen',
    last_name: 'Petersen',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Fabien Petitjean',
    last_name: 'Petitjean',
    ORCID: null,
    google_scholar: 'MGnd_BcAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Rémy Valentin Petkantchin',
    last_name: 'Petkantchin',
    ORCID: '0000-0001-8137-8811',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Spase Petkoski',
    last_name: 'Petkoski',
    ORCID: '0000-0003-4540-6293',
    google_scholar: 'TSi_ZkIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Giovanni Petri',
    last_name: 'Petri',
    ORCID: null,
    google_scholar: 'jb__2PIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Steven Petrou',
    last_name: 'Petrou',
    ORCID: null,
    google_scholar: 'JRoyI2gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Klas H. Pettersen',
    last_name: 'Pettersen',
    ORCID: null,
    google_scholar: 'twaOs6AAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexander Peyser',
    last_name: 'Peyser',
    ORCID: '0000-0002-3453-310X',
    google_scholar: '_vIsa9sAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Weria Pezeshkian',
    last_name: 'Pezeshkian',
    ORCID: '0000-0001-5509-0996',
    google_scholar: 'Lrjf9FgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Maurizio Pezzoli',
    last_name: 'Pezzoli',
    ORCID: '0000-0002-5763-3031',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'L. Pfotzer',
    last_name: 'Pfotzer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ulrike Pfreundt',
    last_name: 'Pfreundt',
    ORCID: null,
    google_scholar: 'B-HQjF4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Eugenio Piasini',
    last_name: 'Piasini',
    ORCID: '0000-0003-0384-7699',
    google_scholar: 'dGKi9Q4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Fabienne Picard',
    last_name: 'Picard',
    ORCID: '0000-0001-9120-1761',
    google_scholar: 'CPN1gVcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alyssa Picchini-Schaffer',
    last_name: 'Picchini-Schaffer',
    ORCID: null,
    google_scholar: 'vK6PpT4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Marina Picciotto',
    last_name: 'Picciotto',
    ORCID: null,
    google_scholar: 'hI_-O8YAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michele Pignatelli',
    last_name: 'Pignatelli',
    ORCID: null,
    google_scholar: 'XbvCRvMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sébastien Piluso',
    last_name: 'Piluso',
    ORCID: null,
    google_scholar: 'KaVBLX4AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Kenneth Pirman',
    last_name: 'Pirman',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Dario Pisignano',
    last_name: 'Pisignano',
    ORCID: null,
    google_scholar: 'fhFk6eYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Francesca Pittau',
    last_name: 'Pittau',
    ORCID: null,
    google_scholar: 'acrwscsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Judit Planas',
    last_name: 'Planas',
    ORCID: '0000-0002-8221-7988',
    google_scholar: 'LdxP4g0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David Plecher',
    last_name: 'Plecher',
    ORCID: null,
    google_scholar: 'N8ksONIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Hans E. Plesser',
    last_name: 'Plesser',
    ORCID: null,
    google_scholar: 'YR5QfVEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Dimitri Plotnikov',
    last_name: 'Plotnikov',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pawel Podhajski',
    last_name: 'Podhajski',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'William F. Podlaski',
    last_name: 'Podlaski',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marin Vlastelica Pogančić',
    last_name: 'Pogančić',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christoph Pokorny',
    last_name: 'Pokorny',
    ORCID: null,
    google_scholar: 'jlR9uNUAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Jean-Baptiste Poline',
    last_name: 'Poline',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mu-ming Poo',
    last_name: 'Poo',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christoph Posch',
    last_name: 'Posch',
    ORCID: null,
    google_scholar: '35iJNvUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alex Pouget',
    last_name: 'Pouget',
    ORCID: null,
    google_scholar: '07IPOvMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Arseny V. Povolotsky',
    last_name: 'Povolotsky',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Christian Pozzorini',
    last_name: 'Pozzorini',
    ORCID: null,
    google_scholar: 'ArkpAjwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Etienne Pralong',
    last_name: 'Pralong',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gautam Prasad',
    last_name: 'Prasad',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'N Pryds',
    last_name: 'Pryds',
    ORCID: null,
    google_scholar: 'aj5SDcsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sirio Bolaños-Puchet',
    last_name: 'Puchet',
    ORCID: '0000-0003-4049-6488',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Vicente Reyes-Puerta',
    last_name: 'Puerta',
    ORCID: null,
    google_scholar: 'XnVpRFkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sangya Pundir',
    last_name: 'Pundir',
    ORCID: '0000-0003-4526-6833',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Julien Puyal',
    last_name: 'Puyal',
    ORCID: null,
    google_scholar: 'tIBrgnkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lei Qu',
    last_name: 'Qu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jia Qu',
    last_name: 'Qu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alain Quentel',
    last_name: 'Quentel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Tyler J Quill',
    last_name: 'Quill',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Adrian Quintana',
    last_name: 'Quintana',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pierre-Olivier Quirion',
    last_name: 'Quirion',
    ORCID: null,
    google_scholar: 'yOX_9z4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Miroslav Radojević',
    last_name: 'Radojević',
    ORCID: null,
    google_scholar: 'bgsGjoIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sri Raghavachari',
    last_name: 'Raghavachari',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'John Rahmon',
    last_name: 'Rahmon',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pranav Rai',
    last_name: 'Rai',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Shravan Tata Ramalingasetty',
    last_name: 'Ramalingasetty',
    ORCID: null,
    google_scholar: 'losb_PYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Arvind Ramanathan',
    last_name: 'Ramanathan',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Srikanth Ramaswamy',
    last_name: 'Ramaswamy',
    ORCID: '0000-0001-6642-7136',
    google_scholar: 'sikKD7UAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Rajnish Ranjan',
    last_name: 'Ranjan',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Shruti Rao',
    last_name: 'Rao',
    ORCID: '0000-0002-7235-0777',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Andrea Redaelli',
    last_name: 'Redaelli',
    ORCID: null,
    google_scholar: 'rFoJ16sAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christopher A. Reid',
    last_name: 'Reid',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Michael W. Reimann',
    last_name: 'Reimann',
    ORCID: '0000-0003-3455-2367',
    google_scholar: 'uzjd33kAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Francesco Resta',
    last_name: 'Resta',
    ORCID: null,
    google_scholar: 'T2xDw1wAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Leonardo Restivo',
    last_name: 'Restivo',
    ORCID: null,
    google_scholar: 'fjFf8boAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Maria Reva',
    last_name: 'Reva',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Imad Riachi',
    last_name: 'Riachi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Carlo Ricciardi',
    last_name: 'Ricciardi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Renaud Luc Richardet',
    last_name: 'Richardet',
    ORCID: null,
    google_scholar: 'IieFwtwAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Magnus J E Richardson',
    last_name: 'Richardson',
    ORCID: null,
    google_scholar: 'jA9lgb8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Paul Richmond',
    last_name: 'Richmond',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Killian Rigaux',
    last_name: 'Rigaux',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nathan Riguet',
    last_name: 'Riguet',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Henri Riihimäki',
    last_name: 'Riihimäki',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Fabio Rinaldi',
    last_name: 'Rinaldi',
    ORCID: '0000-0001-5718-5462',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Juan Luis Riquelme',
    last_name: 'Riquelme',
    ORCID: null,
    google_scholar: 'l4WgXV0AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Martina F. Rizza',
    last_name: 'Rizza',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Anne‐Bérengère Rocher',
    last_name: 'Rocher',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Kathleen S. Rockland',
    last_name: 'Rockland',
    ORCID: '0000-0002-6650-8785',
    google_scholar: 'jeIa4nUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Dimitri Rodarie',
    last_name: 'Rodarie',
    ORCID: null,
    google_scholar: '9qt-bvYAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Perin Rodrigo',
    last_name: 'Rodrigo',
    ORCID: null,
    google_scholar: '_EkSlfYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'José-Rodrigo Rodríguez',
    last_name: 'Rodríguez',
    ORCID: null,
    google_scholar: '8fdHZBUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Raul Rodriguez-Esteban',
    last_name: 'Rodriguez-Esteban',
    ORCID: '0000-0002-9494-9609',
    google_scholar: 'FsXiVDwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Florian Roehrbein',
    last_name: 'Roehrbein',
    ORCID: null,
    google_scholar: 'IEOJBbAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Arne Roennau',
    last_name: 'Roennau',
    ORCID: null,
    google_scholar: 'LV3qgLQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Noa Rogozinski',
    last_name: 'Rogozinski',
    ORCID: '0000-0001-7130-0375',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alina Roitberg',
    last_name: 'Roitberg',
    ORCID: null,
    google_scholar: 'UuEFRDoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ariel Rokem',
    last_name: 'Rokem',
    ORCID: null,
    google_scholar: 'hrBeLVYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Benjamin R. K. Roller',
    last_name: 'Roller',
    ORCID: null,
    google_scholar: 'oocmzDgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bogdan Roman',
    last_name: 'Roman',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Sandrine Romand',
    last_name: 'Romand',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Armando Romani',
    last_name: 'Romani',
    ORCID: '0000-0001-6388-4286',
    google_scholar: 'IW4gZH0AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Eduardo Ros',
    last_name: 'Ros',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Nadia Rosenberg',
    last_name: 'Rosenberg',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jane Roskams',
    last_name: 'Roskams',
    ORCID: null,
    google_scholar: 'nzIRBZ0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christian A. Rössert',
    last_name: 'Rössert',
    ORCID: '0000-0002-4839-2424',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jean Rossier',
    last_name: 'Rossier',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David Rotenberg',
    last_name: 'Rotenberg',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Arnd Roth',
    last_name: 'Roth',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Karen Rothfels',
    last_name: 'Rothfels',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yann Roussel',
    last_name: 'Roussel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Andrew Rowley',
    last_name: 'Rowley',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Badrinath Roysam',
    last_name: 'Roysam',
    ORCID: null,
    google_scholar: 'JL4E0ZoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Zongcai Ruan',
    last_name: 'Ruan',
    ORCID: null,
    google_scholar: 'w0JRZWUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Edwin Rubel',
    last_name: 'Rubel',
    ORCID: null,
    google_scholar: '12aRrqIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'John L. R. Rubenstein',
    last_name: 'Rubenstein',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Bernardo Rudy',
    last_name: 'Rudy',
    ORCID: '0000-0001-5748-6900',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Joan Rue-Queralt',
    last_name: 'Rue-Queralt',
    ORCID: null,
    google_scholar: '6cCLjg0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bernhard Rumpe',
    last_name: 'Rumpe',
    ORCID: '0000-0002-2147-1966',
    google_scholar: 'nED1C7QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ilya A. Rybak',
    last_name: 'Rybak',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ziad S. Saad',
    last_name: 'Saad',
    ORCID: null,
    google_scholar: 'XpdrHm0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Leonardo Sacconi',
    last_name: 'Sacconi',
    ORCID: null,
    google_scholar: 'Rkqz0J8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sadra Sadeh',
    last_name: 'Sadeh',
    ORCID: null,
    google_scholar: 'TTSaP7wAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Florentino Sainz',
    last_name: 'Sainz',
    ORCID: null,
    google_scholar: 'U4htCNYAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David Salgado',
    last_name: 'Salgado',
    ORCID: '0000-0002-5905-3591',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alberto Salleo',
    last_name: 'Salleo',
    ORCID: null,
    google_scholar: '5XjscEAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Matteo Santoro',
    last_name: 'Santoro',
    ORCID: '0000-0002-4457-4204',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'João P. G. Santos',
    last_name: 'Santos',
    ORCID: '0000-0002-0395-039X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Riccardo Sapienza',
    last_name: 'Sapienza',
    ORCID: '0000-0002-4208-0374',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sára Sáray',
    last_name: 'Sáray',
    ORCID: '0000-0002-4341-177X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ausra Saudargienė',
    last_name: 'Saudargienė',
    ORCID: '0000-0003-2768-3334',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alexandru Săvulescu',
    last_name: 'Săvulescu',
    ORCID: null,
    google_scholar: 'M5O9XpoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Dhruv Saxena',
    last_name: 'Saxena',
    ORCID: null,
    google_scholar: 'YojKX5YAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Enrico Scantamburlo',
    last_name: 'Scantamburlo',
    ORCID: '0000-0003-4598-3998',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Massimo Scanziani',
    last_name: 'Scanziani',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mary Schaeffer',
    last_name: 'Schaeffer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Franz Scherr',
    last_name: 'Scherr',
    ORCID: null,
    google_scholar: 'QCUInkoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alon Schindel',
    last_name: 'Schindel',
    ORCID: null,
    google_scholar: 'KJ2adaIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Michael Schmuker',
    last_name: 'Schmuker',
    ORCID: null,
    google_scholar: 'sUf4Zn4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ralf Schneggenburger',
    last_name: 'Schneggenburger',
    ORCID: null,
    google_scholar: 'k_2snewAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Lars Schneidenbach',
    last_name: 'Schneidenbach',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mikkel Schöttner',
    last_name: 'Schöttner',
    ORCID: null,
    google_scholar: 'XQbbbysAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rouven Schulz',
    last_name: 'Schulz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Till Schumann',
    last_name: 'Schumann',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Felix Schuermann',
    last_name: 'Schürmann',
    ORCID: '0000-0001-5379-8560',
    google_scholar: 'FGw4sMEAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Martina Scolamiero',
    last_name: 'Scolamiero',
    ORCID: null,
    google_scholar: 'hWJhusMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Abu Sebastian',
    last_name: 'Sebastian',
    ORCID: null,
    google_scholar: 'sUCQ7KMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexander Seeholzer',
    last_name: 'Seeholzer',
    ORCID: '0000-0002-1541-4906',
    google_scholar: 'n4aBht4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Idan Segev',
    last_name: 'Segev',
    ORCID: '0000-0001-7279-9630',
    google_scholar: 'JDhMq3kAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexandra H. Seidenstein',
    last_name: 'Seidenstein',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Terry Sejnowski',
    last_name: 'Sejnowski',
    ORCID: null,
    google_scholar: 'm1qAiOUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Konstantinos Sfyrakis',
    last_name: 'Sfyrakis',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Martina Sgritta',
    last_name: 'Sgritta',
    ORCID: null,
    google_scholar: 'jalYbmoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gilad Shapira',
    last_name: 'Shapira',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Matthew Shardlow',
    last_name: 'Shardlow',
    ORCID: null,
    google_scholar: 'RMA0XzoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gordon M. Shepherd',
    last_name: 'Shepherd',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Chet C. Sherwood',
    last_name: 'Sherwood',
    ORCID: null,
    google_scholar: 'ENmARrsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ying Shi',
    last_name: 'Shi',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Yonggang Shi',
    last_name: 'Shi',
    ORCID: null,
    google_scholar: 'sm2Y-6sAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Polina Shichkova',
    last_name: 'Shichkova',
    ORCID: '0000-0002-2574-1732',
    google_scholar: 'iXUKm-wAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Julian C. Shillcock',
    last_name: 'Shillcock',
    ORCID: '0000-0002-7885-735X',
    google_scholar: 'dTWd7VIAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Sandra Siegert',
    last_name: 'Siegert',
    ORCID: null,
    google_scholar: 'Kus1pPwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joshua H. Siegle',
    last_name: 'Siegle',
    ORCID: null,
    google_scholar: 'jxKYDrEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gilad Silberberg',
    last_name: 'Silberberg',
    ORCID: '0000-0001-9964-505X',
    google_scholar: 'DvA0EPQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Raquel M. Silva',
    last_name: 'Silva',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ricardo Silva',
    last_name: 'Silva',
    ORCID: '0009-0001-0951-4628',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'R. Angus Silver',
    last_name: 'Silver',
    ORCID: null,
    google_scholar: 'rpON82IAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Ludovico Silvestri',
    last_name: 'Silvestri',
    ORCID: '0000-0001-9215-9104',
    google_scholar: 'WN2BD24AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jane Simko',
    last_name: 'Simko (Yi)',
    ORCID: '0000-0002-3632-4176',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Michel Simonneau',
    last_name: 'Simonneau',
    ORCID: null,
    google_scholar: 'VdcDl84AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Onkar Singh',
    last_name: 'Singh',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Upinder Singh Bhalla',
    last_name: 'Singh Bhalla',
    ORCID: null,
    google_scholar: 'rxYd-B4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Amos Sironi',
    last_name: 'Sironi',
    ORCID: null,
    google_scholar: 'wVYKFnAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Subhashini Sivagnanam',
    last_name: 'Sivagnanam',
    ORCID: null,
    google_scholar: 'x5WpsHMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'P. J. Sjöström',
    last_name: 'Sjöström',
    ORCID: null,
    google_scholar: 'ikK_8p8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David Slater',
    last_name: 'Slater',
    ORCID: '0000-0003-1809-5661',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Stefan Slesazeck',
    last_name: 'Slesazeck',
    ORCID: null,
    google_scholar: '1greZkoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jonasz Słomka',
    last_name: 'Słomka',
    ORCID: '0000-0002-7097-5810',
    google_scholar: 'i1vhVigAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Roberto Smiriglia',
    last_name: 'Smiriglia',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jason P. Smith',
    last_name: 'Smith',
    ORCID: null,
    google_scholar: 'XMRu848AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sergio Solinas',
    last_name: 'Solinas',
    ORCID: '0000-0002-8605-8515',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Liviu Soltuzu',
    last_name: 'Soltuzu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Fritz Sommer',
    last_name: 'Sommer',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Peter Somogyi',
    last_name: 'Somogyi',
    ORCID: null,
    google_scholar: '0v7auZwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Vishal Sood',
    last_name: 'Sood',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Austin Soplata',
    last_name: 'Soplata',
    ORCID: null,
    google_scholar: 'nkhwczcAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Victor H. Souza',
    last_name: 'Souza',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Cristina Spalletti',
    last_name: 'Spalletti',
    ORCID: null,
    google_scholar: 'cx6MFMoAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sebastien Speierer',
    last_name: 'Speierer',
    ORCID: null,
    google_scholar: 'ANbGE-UAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Sabina Spiga',
    last_name: 'Spiga',
    ORCID: null,
    google_scholar: '90EIQRUAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Nelson Spruston',
    last_name: 'Spruston',
    ORCID: '0000-0003-3118-1636',
    google_scholar: 'wjdLQDkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'V. Srinivasa Chakravarthy',
    last_name: 'Srinivasa Chakravarthy',
    ORCID: null,
    google_scholar: '4PqxB3gAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Mohit Srivastava',
    last_name: 'Srivastava',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Natalia Stafeeva',
    last_name: 'Stafeeva',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Jochen F. Staiger',
    last_name: 'Staiger',
    ORCID: null,
    google_scholar: '-67Zko4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rokas Stanislovas',
    last_name: 'Stanislovas',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Raymund Stefancsik',
    last_name: 'Stefancsik',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David M. Steinberg',
    last_name: 'Steinberg',
    ORCID: null,
    google_scholar: 'vCPW4KMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andrey Stepaniuk',
    last_name: 'Stepaniuk',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Thomas Sterling',
    last_name: 'Sterling',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Janine Stevens',
    last_name: 'Stevens',
    ORCID: null,
    google_scholar: 'gnZKy0oAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Klaus M. Stiefel',
    last_name: 'Stiefel',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marcel Stimberg',
    last_name: 'Stimberg',
    ORCID: null,
    google_scholar: 'KJs3XswAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Roman Stocker',
    last_name: 'Stocker',
    ORCID: '0000-0002-3199-0508',
    google_scholar: 'cSsdfFcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alan B. Stokes',
    last_name: 'Stokes',
    ORCID: null,
    google_scholar: 'AkKmS_EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'John Paul Strachan',
    last_name: 'Strachan',
    ORCID: null,
    google_scholar: '8ay1zbcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stephen C. Strother',
    last_name: 'Strother',
    ORCID: '0000-0002-3198-217X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Chu-Hsien Su',
    last_name: 'Su',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Suresh Subramani',
    last_name: 'Subramani',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shyam Sudkahar',
    last_name: 'Sudkahar',
    ORCID: null,
    google_scholar: 'Zm7WsIsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Uygar Sümbül',
    last_name: 'Sümbül',
    ORCID: null,
    google_scholar: 'dhiRjJIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jyh-Jang Sun',
    last_name: 'Sun',
    ORCID: null,
    google_scholar: 'RZ4xDckAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Larry Swanson',
    last_name: 'Swanson',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Mohameth François Sy',
    last_name: 'Sy',
    ORCID: null,
    google_scholar: 'xYMSpVAAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Davide Tacchella',
    last_name: 'Tacchella',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Valérie Tâche',
    last_name: 'Tâche',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Hamsa D. Tadepally',
    last_name: 'Tadepally',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gábor Tamás',
    last_name: 'Tamás',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Gouhei Tanaka',
    last_name: 'Tanaka',
    ORCID: null,
    google_scholar: 'rNP9cAgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jonathan Tapson',
    last_name: 'Tapson',
    ORCID: null,
    google_scholar: 'BOIxudIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bosiljka Tasic',
    last_name: 'Tasic',
    ORCID: null,
    google_scholar: 'M7tnFRQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Farhan Tauheed',
    last_name: 'Tauheed',
    ORCID: null,
    google_scholar: 'JU-zSOkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Martin Telefont',
    last_name: 'Telefont',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Aleksandra Teska',
    last_name: 'Teska',
    ORCID: null,
    google_scholar: 's9QzbuQAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Guilherme Testa-Silva',
    last_name: 'Testa-Silva',
    ORCID: '0000-0002-5585-1166',
    google_scholar: 'qrt6XFgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tom Tetzlaff',
    last_name: 'Tetzlaff',
    ORCID: null,
    google_scholar: 'Cx05PFgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joseph Tharayil',
    last_name: 'Tharayil',
    ORCID: null,
    google_scholar: 'ips0hM8AAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Axel Thielscher',
    last_name: 'Thielscher',
    ORCID: null,
    google_scholar: 'ODZnZbgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'David B. Thomas',
    last_name: 'Thomas',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alex M. Thomson',
    last_name: 'Thomson',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Simon Thorpe',
    last_name: 'Thorpe',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Paul Tiesinga',
    last_name: 'Tiesinga',
    ORCID: null,
    google_scholar: 'GnKVOBwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Antonino Tocco',
    last_name: 'Tocco',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Arthur Toga',
    last_name: 'Toga',
    ORCID: null,
    google_scholar: 'B2YRmGgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Maria Toledo-Rodriguez',
    last_name: 'Toledo-Rodriguez',
    ORCID: null,
    google_scholar: 'go2rlO0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Silvia Tolu',
    last_name: 'Tolu',
    ORCID: null,
    google_scholar: 'ITsafnMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Adam Tomkins',
    last_name: 'Tomkins',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Roberto Toro',
    last_name: 'Toro',
    ORCID: '0000-0002-6671-858X',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Georgia Tourassi',
    last_name: 'Tourassi',
    ORCID: null,
    google_scholar: 'mBRzPfIAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sebastien Tourbier',
    last_name: 'Tourbier',
    ORCID: null,
    google_scholar: 'Fuxa02EAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Tränkler',
    last_name: 'Tränkler',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Guido Trensch',
    last_name: 'Trensch',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Shreejoy J. Tripathy',
    last_name: 'Tripathy',
    ORCID: null,
    google_scholar: 'dNybyvgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Daniel Trpevski',
    last_name: 'Trpevski',
    ORCID: null,
    google_scholar: 'JuCAs-0AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Li-Huei Tsai',
    last_name: 'Tsai',
    ORCID: null,
    google_scholar: 'LX18xbEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Loukia Tsaprouni',
    last_name: 'Tsaprouni',
    ORCID: null,
    google_scholar: 'O3bsHYQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Misha Tsodyks',
    last_name: 'Tsodyks',
    ORCID: '0000-0002-5661-4349',
    google_scholar: 'qQgUtucAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anil M. Tuncel',
    last_name: 'Tuncel',
    ORCID: null,
    google_scholar: 'bTmGQ3cAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Katharine Turner',
    last_name: 'Turner',
    ORCID: '0000-0002-6679-7441',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Lucina Q. Uddin',
    last_name: 'Uddin',
    ORCID: null,
    google_scholar: 'ZRJpD7wAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexander D. Ulbrich',
    last_name: 'Ulbrich',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Stefan Ulbrich',
    last_name: 'Ulbrich',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Francesco Vaccarino',
    last_name: 'Vaccarino',
    ORCID: null,
    google_scholar: '4XfzoZQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Andrea C. Vaiana',
    last_name: 'Vaiana',
    ORCID: null,
    google_scholar: 'YSVlWhwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alexandre Valentian',
    last_name: 'Valentian',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Taufik A. Valiante',
    last_name: 'Valiante',
    ORCID: '0000-0002-3443-3790',
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ilia Valov',
    last_name: 'Valov',
    ORCID: null,
    google_scholar: 'kSKH_z8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sacha J. van Albada',
    last_name: 'van Albada',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Kimberly Van Auken',
    last_name: 'Van Auken',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Patrick van der Smagt',
    last_name: 'van der Smagt',
    ORCID: '0000-0003-4418-4916',
    google_scholar: '5ybzvbsAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Werner A. H. Van Geit',
    last_name: 'Van Geit',
    ORCID: '0000-0002-2915-720X',
    google_scholar: '5sr9FJgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'John D. Van Horn',
    last_name: 'Van Horn',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'John W. Van Meter',
    last_name: 'Van Meter',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Pierre Vandergheynst',
    last_name: 'Vandergheynst',
    ORCID: null,
    google_scholar: '1p9NOFEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Liesbeth Vanherpe',
    last_name: 'Vanherpe',
    ORCID: null,
    google_scholar: 'ln2CsAEAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Lorenzo Vannucci',
    last_name: 'Vannucci',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Francesco Vanzi',
    last_name: 'Vanzi',
    ORCID: '0000-0002-6372-7747',
    google_scholar: 'oGd906oAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Nicole Vasilevsky',
    last_name: 'Vasilevsky',
    ORCID: '0000-0001-5208-3432',
    google_scholar: 'NqjaPaAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Xavier Vasques',
    last_name: 'Vasques',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Juan Camilo Vasquez Tieck',
    last_name: 'Vasquez Tieck',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Marie Vasse',
    last_name: 'Vasse',
    ORCID: null,
    google_scholar: 'vxdv_aAAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rahul V. Veettil',
    last_name: 'Veettil',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Alessandro Venturino',
    last_name: 'Venturino',
    ORCID: null,
    google_scholar: '5omV568AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Csaba Verasztó',
    last_name: 'Verasztó',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Matthijs B Verhoog',
    last_name: 'Verhoog',
    ORCID: null,
    google_scholar: 'lrKeXBEAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Tamara Versluis',
    last_name: 'Versluis',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Elisa Vianello',
    last_name: 'Vianello',
    ORCID: null,
    google_scholar: 'hscO0hMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Alessandro E. P. Villa',
    last_name: 'Villa',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jafet Villafranca',
    last_name: 'Villafranca',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Paola Vitale',
    last_name: 'Vitale',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Tim P. Vogels',
    last_name: 'Vogels',
    ORCID: null,
    google_scholar: 'RsHPZroAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'R. Jacob Vogelstein',
    last_name: 'Vogelstein',
    ORCID: null,
    google_scholar: 'u7l9GEkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Joshua T. Vogelstein',
    last_name: 'Vogelstein',
    ORCID: null,
    google_scholar: 'DWPfdT4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Christine Axel Von Arnim',
    last_name: 'Von Arnim',
    ORCID: null,
    google_scholar: 'OJ4tD18AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Mark L. Vousden',
    last_name: 'Vousden',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Laurent Vuillon',
    last_name: 'Vuillon',
    ORCID: null,
    google_scholar: 'XYUUU0QAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Thomas Wachtler',
    last_name: 'Wachtler',
    ORCID: '0000-0003-2015-6590',
    google_scholar: 'nOfu8roAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Rebecca C Wade',
    last_name: 'Wade',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Wojciech Wajerowicz',
    last_name: 'Wajerowicz',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Richard Walker',
    last_name: 'Walker',
    ORCID: '0000-0003-2123-5565',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Ching-Wei Wang',
    last_name: 'Wang',
    ORCID: null,
    google_scholar: 'k_oz6FgAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Qinghua Wang',
    last_name: 'Wang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Xiaodong Wang',
    last_name: 'Wang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yuan Wang',
    last_name: 'Wang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Yun Wang',
    last_name: 'Wang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'T. J. Christopher Ward',
    last_name: 'Ward',
    ORCID: null,
    google_scholar: 'JfCiCZMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Sandro Weber',
    last_name: 'Weber',
    ORCID: null,
    google_scholar: 'lps2OugAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bruno Weber',
    last_name: 'Weber',
    ORCID: '0000-0002-9089-0689',
    google_scholar: 'RnsnW3sAAAAJ&hl',
    'bbp-member': false,
  },
  {
    full_name: 'Yina Wei',
    last_name: 'Wei',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Konstantin Weise',
    last_name: 'Weise',
    ORCID: null,
    google_scholar: '3KVsuA8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Gerhard Wellein',
    last_name: 'Wellein',
    ORCID: null,
    google_scholar: 'Fk1KSNMAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Robert C. Welsh',
    last_name: 'Welsh',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'David C. West',
    last_name: 'West',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jeanette D. Wheeler',
    last_name: 'Wheeler',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'René Wilbers',
    last_name: 'Wilbers',
    ORCID: null,
    google_scholar: 'jz_pG7UAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Jonathan Wirsich',
    last_name: 'Wirsich',
    ORCID: null,
    google_scholar: '_X4xQNcAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Matthias Wolf',
    last_name: 'Wolf',
    ORCID: '0000-0002-6997-6330',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Marmaduke Woodman',
    last_name: 'Woodman',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Torge Worbs',
    last_name: 'Worbs',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Cathy H. Wu',
    last_name: 'Wu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Willem A. M. Wybo',
    last_name: 'Wybo',
    ORCID: null,
    google_scholar: 'Q9_bcNMAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Ting Xu',
    last_name: 'Xu',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Xiaohan Xue',
    last_name: 'Xue',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Sophia N. Yaliraki',
    last_name: 'Yaliraki',
    ORCID: null,
    google_scholar: 'RGH5t94AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'J Joshua Yang',
    last_name: 'Yang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jian Yang',
    last_name: 'Yang',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jianhua Yao',
    last_name: 'Yao',
    ORCID: null,
    google_scholar: '3bQwlCQAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Peng Yao',
    last_name: 'Yao',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Zizhen Yao',
    last_name: 'Yao',
    ORCID: null,
    google_scholar: 'EYosRt4AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stuart Yates',
    last_name: 'Yates',
    ORCID: null,
    google_scholar: '8bs77jQAAAAJ',
    'bbp-member': true,
  },
  {
    full_name: 'Bing Ye',
    last_name: 'Ye',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Hongchuan Yu',
    last_name: 'Yu',
    ORCID: null,
    google_scholar: 'k0u7iVkAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Wang Yun',
    last_name: 'Yun',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Rafael Yuste',
    last_name: 'Yuste',
    ORCID: null,
    google_scholar: 'G0WcJagAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Anthony Zador',
    last_name: 'Zador',
    ORCID: null,
    google_scholar: 'wM_22hwAAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Bas-Jan Zandt',
    last_name: 'Zandt',
    ORCID: '0000-0003-3546-7228',
    google_scholar: 'ry1UpU8AAAAJ',
    'bbp-member': false,
  },
  {
    full_name: 'Stefano M. Zaninetta',
    last_name: 'Zaninetta',
    ORCID: null,
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Mickael Zbili',
    last_name: 'Zbili',
    ORCID: '0000-0002-7377-2605',
    google_scholar: null,
    'bbp-member': true,
  },
  {
    full_name: 'Hongkui Zeng',
    last_name: 'Zeng',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ting Zhao',
    last_name: 'Zhao',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Ning Zhong',
    last_name: 'Zhong',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Jie Zhou',
    last_name: 'Zhou',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Zhi Zhou',
    last_name: 'Zhou',
    ORCID: null,
    google_scholar: null,
    'bbp-member': false,
  },
  {
    full_name: 'Eleftherios Zisis',
    last_name: 'Zisis',
    ORCID: null,
    google_scholar: '4mieLgQAAAAJ',
    'bbp-member': true,
  },
];
